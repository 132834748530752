import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetcher } from '../../libs/utilHooks';
import { dataServiceApi } from '../api';
import { fetchDataServices } from './actions';
import { dataServicesMapByDataProviderIdSelector } from './selectors';

// ===============
// Use dataService
// ===============

export const useDataServiceByDataProvider = (dataProviderId, withHidden = true) => {
  const emptyArray = React.useRef([]).current;
  const dataServices = useSelector(state => dataServicesMapByDataProviderIdSelector(state)[dataProviderId] || emptyArray);
  return React.useMemo(() => withHidden ? dataServices : dataServices.filter(ds => !ds.hidden), [withHidden, dataServices]);
};

export const useDataServiceByDataProviders = (dataProviderIds) => {
  const emptyArray = React.useRef([]).current;
  const dataServicesMapByDataProvider = useSelector(dataServicesMapByDataProviderIdSelector);
  return React.useMemo(() => dataProviderIds.reduce((previous, id) => ({ ...previous, [id]: dataServicesMapByDataProvider[id] || emptyArray }), {}), [dataProviderIds, dataServicesMapByDataProvider]);
};


// ========================
// Use dataService commands
// ========================

const TIMEOUT_STATUS = 1500;

export const IS_NONE = 'NONE';
export const IS_FETCHING = 'IS_FETCHING';
export const IS_ERROR = 'IS_ERROR';
export const IS_SUCCESS = 'IS_SUCCESS';

const useCommandApi = (apiFunc, resetTimeout) => {
  const [commandState, setCommandState] = React.useState(IS_NONE);
  const command = (...apiFuncArg) => {
    if (commandState != IS_NONE)
      return false;
    apiFunc(...apiFuncArg)
      .then(() => setCommandState(IS_SUCCESS))
      .catch(() => setCommandState(IS_ERROR))
      .finally(() => setTimeout(() => setCommandState(IS_NONE), resetTimeout));
    return true;
  };
  return [command, commandState];
};

const useTakePicture = () => useCommandApi(dataServiceApi.sendCommandPicture, TIMEOUT_STATUS);
const useSetDigitalOutput = () => useCommandApi(dataServiceApi.sendCommandDigitalOutput, TIMEOUT_STATUS);
const useSetBuzz = () => useCommandApi(dataServiceApi.sendCommandBuzz, TIMEOUT_STATUS);

/**
 * All hook return array with command function and command status
 */
export const DataServiceCommandHooks = {
  useTakePicture,
  useSetDigitalOutput,
  useSetBuzz
};

// =====
// Fetch
// =====

export const useFetchDataService = () => {
  const dispatch = useDispatch();
  return (dataProviderId) => dispatch(fetchDataServices(dataProviderId));
};


export const useFetchData = (dataService) => {
  const [data, setData] = React.useState([]);
  const fetch = (date = new Date()) => dataServiceApi.fetchDataByDate(dataService.id, date)
    .then(fetchRes => setData(Object.entries(fetchRes.values)))
    .catch(() => { });
  const fetchData = React.useMemo(() => fetch, [dataService]
  );
  return [data, fetchData];
};

const isEqual = (newValue = {}, oldValue = {}) => Object.keys(newValue)[0] === Object.keys(oldValue)[0];

export const useFetchDataLastValue = (dataService) => {
  const [dataLastValue, setDataLastValue] = React.useState(undefined);
  const previous = React.useRef(undefined);
  previous.current = dataLastValue;
  const fetch = () => dataServiceApi
    .fetchDataLastValue(dataService.id)
    .then(fetchRes => (isEqual(fetchRes, previous.current)) ? null : setDataLastValue(fetchRes))
    .catch(() => { });
  const fetchDataLastValue = React.useMemo(() => fetch, [dataService]);
  return [dataLastValue, fetchDataLastValue];
};

export const useFetchDataImage = (imageName) => {
  return useFetcher(() => dataServiceApi.fetchDataImage(imageName), [imageName]);
};
