import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=3F51B5&secondary.color=F44336

export const theme = createTheme({
  primaryAppBarHeight: 80,
  secondaryAppBarHeight: 70,
  drawerWidth: 300,
  palette: {
    background: {
      default: "#75898d30"
    },
    primary: {
      light: '#78bccb',
      main: '#468c9a',
      dark: '#085f6c',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#d1f7c1',
      main: '#9fc490',
      dark: '#709362',
      contrastText: '#ffffff',
    },
    navbar: {
      light: '#fefefe',
      main: '#d4d4d4',
      dark: '#bbc7ca',
      contrastText: '#444e4c',
    }
  }
});

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    neutral: red
  },
});
