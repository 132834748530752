import { Button, Checkbox, Container, FormControl, FormControlLabel, InputAdornment, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { AccountCircle, LockRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../../assets/logo/we-sens.png';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    top: '50%',
    position: 'relative',
    transform: 'translateY(-50%)',
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    '& .linkContainer': {
      marginTop: theme.spacing(10)
    },
  },
  logoContainer: {
    marginBottom: theme.spacing(10),
    "& .loginLogo": {
      width: '100%',
    }
  }
}));

const propTypes = {
  onLogin: PropTypes.func
};

const defaultProps = {
  onLogin: () => { }
};

const LoginForm = ({ onLogin }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const login = React.useRef('');
  const password = React.useRef('');
  const handleLogin = React.useRef(() => onLogin(login.current, password.current)).current;
  return (
    <Container className={classes.formContainer}>
      <div className={classes.logoContainer}>
        <img className="loginLogo" alt="we-sens" title="we-sens" src={Logo} />
        <Typography align="right" variant="h4" color="primary" className={classes.title} noWrap>MANAGER</Typography>
      </div>
      <form>
        <FormControl fullWidth>
          <TextField
            onChange={event => login.current = event.target.value}
            defaultValue={login.current}
            placeholder="e.g. example@email.com"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            label={t('login.email')} variant="outlined" />
          <TextField
            onChange={event => password.current = event.target.value}
            placeholder="********"
            defaultValue={password.current}
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockRounded />
                </InputAdornment>
              ),
            }}
            label={t('login.password')} variant="outlined" />
        </FormControl>
        <div>
          <FormControlLabel
            value="end"
            control={<Checkbox size="small" color="primary" />}
            label={t('login.remember_me')}
            labelPlacement="end"
          />
        </div>
        <div>
          <Button variant="contained" color="primary" onClick={handleLogin}>
            {t('login.login_button')}
          </Button>
        </div>
      </form>
      <div className="linkContainer">
        <a href=''>{t('login.create_account')}</a>
        <br />
        <a href=''>{t('login.reset_password')}</a>
      </div>
    </Container>
  );
};

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
