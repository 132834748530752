import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClusterMetrics } from './actions';
import { clusterMetricsIsFetchingSelector, clusterMetricsMapSelector } from './selectors';

// =====
// Store
// =====

export const useClusterMetrics = (clusterId) => {
  const clusterMetrics = useSelector(state => clusterMetricsMapSelector(state)[clusterId]);
  const isFetching = useSelector(state => clusterMetricsIsFetchingSelector(state));
  return [clusterMetrics, isFetching];
};

export const useFetchClusterMetrics = (clusterId) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchClusterMetrics(clusterId));
  }, [clusterId]);
};
