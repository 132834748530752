import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Link, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddAPhoto as AddAPhotoIcon, Share as ShareIcon } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIconSelected from '@material-ui/icons/Star';
import { DropzoneDialog } from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useGetImageCluster, useUpsertCluster, useUpsertImageCluster } from '../../modules/moduleCluster/hooks';
import ClusterEditDialog from '../shared/ClusterEditDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  media: {
    position: 'relative',
    // paddingTop: '56.25%', // 16:9
    height: 256
  },
  openButton: {
    borderRadius: 0
  },
  floatingStatus: (cluster) => ({
    zIndex: 20,
    position: "absolute",
    backgroundColor: theme.palette[cluster.enable ? 'success' : 'error'].light,
    color: theme.palette[cluster.enable ? 'success' : 'error'].contrastText,
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(0.1),
    paddingBottom: theme.spacing(0.1),
    top: theme.spacing(1),
    left: theme.spacing(1),
    minWidth: theme.spacing(8)
  }),
  floatingEditButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
}));

const propTypes = {
  cluster: PropTypes.object.isRequired,
  openable: PropTypes.bool,
  editable: PropTypes.bool
};

const ClusterCard = ({ cluster, openable, editable, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles(cluster);
  const owner = cluster.cluster_memberships.find(membership => membership.user.id === cluster.owner).user;
  const [[openEdit, openImageDropzone], setOpenEdit] = React.useState([false, false]);

  const [image] = useGetImageCluster(cluster);
  const upsertCluster = useUpsertCluster();
  const upsertImageCluster = useUpsertImageCluster();

  const onSaveClusterEdit = (c) => { upsertCluster(c); setOpenEdit([false, false]); };
  const onSaveClusterImageUpdate = (files) => { upsertImageCluster(cluster, files[0]); setOpenEdit([false, false]); };

  return (
    <React.Fragment>
      <Card variant='outlined' className={ classes.root } { ...props }>
        <Paper fontWeight='bold' color='success' variant='elevation' className={ classes.floatingStatus } elevation={ 0 }>
          <Typography variant="body2" align='center'>
            <strong><span>{ cluster.enable ? t('clusters.card.active') : t('clusters.card.inactive') }</span></strong>
          </Typography>
        </Paper>
        <CardMedia
          className={ classes.media }
          component='img'
          src={ image }
          title={ cluster.name } />
        <CardHeader
          action={ editable &&
            <React.Fragment>
              <IconButton onClick={ () => setOpenEdit([true, false]) }><EditIcon /></IconButton>
              <IconButton onClick={ () => setOpenEdit([false, true]) }><AddAPhotoIcon /></IconButton>
            </React.Fragment>
          }
          title={ cluster.name }
          subheader={ <Link href="#">{ `@${owner.first_name} ${owner.last_name}` }</Link> }
        />
        <CardContent>
          <Typography
            paragraph
            variant="caption"
            color="textSecondary"
            style={ { height: '4em', overflowY: 'auto' } }
            title={ cluster.description }>
            { cluster.description || t('dashboard.no_description') }
          </Typography>
        </CardContent>
        <CardActions disableSpacing >
          <IconButton>
            <FavoriteIconSelected />
          </IconButton>
          <IconButton>
            <ShareIcon />
          </IconButton>
        </CardActions>
        { openable && <Button className={ classes.openButton } color="default" variant="contained" fullWidth size="small" to={ `cluster/${cluster.id}/dashboard` } component={ NavLink }>{ t('clusters.card.button_open') }</Button> }
      </Card>
      <ClusterEditDialog
        initialCluster={ cluster }
        open={ openEdit }
        onClose={ () => setOpenEdit([false, false]) }
        onSave={ onSaveClusterEdit } />
      <DropzoneDialog
        filesLimit={ 1 }
        open={ openImageDropzone }
        onClose={ () => setOpenEdit([false, false]) }
        onSave={ onSaveClusterImageUpdate } />
    </React.Fragment >
  );
};

ClusterCard.propTypes = propTypes;

export default ClusterCard;
