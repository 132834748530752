import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from '@material-ui/data-grid';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useWebsocketError } from "../../../modules/hooks";
import { useBluetoothDevicePair, useBluetoothDeviceUnpair, useDevicesByGateway, useScannedBluetoothDevicesByGateway, useScanOn } from "../../../modules/moduleDataProvider/hooks";
import { GenericDialog } from '../../lib';

const useBluetoothDeviceListStyle = makeStyles({
  root: {
    height: 400,
    width: 540
  },
  rowConnected: {
    backgroundColor: '#00800042'
  }
});

const bluetoothDeviceListPropTypes = {
  gatewayId: PropTypes.number.isRequired,
  handleConnect: PropTypes.func.isRequired,
  handleDisconnect: PropTypes.func.isRequired
};

const BluetoothDeviceList = ({ gatewayId, handleConnect, handleDisconnect }) => {
  const { t } = useTranslation();
  const style = useBluetoothDeviceListStyle();
  const devices = useDevicesByGateway(gatewayId);
  const scannedDevices = useScannedBluetoothDevicesByGateway(gatewayId);
  const rows = React.useMemo(() => [...devices, ...scannedDevices.map((d) => { return { ...d, id: d.mac, connectivity: 'scan' }; })], [devices, scannedDevices]);
  const columns = [
    // { field: 'id', headerName: 'ID', width: 50 },
    { field: 'name', headerName: t('providers.dialog.bluetooth.name'), width: 160 },
    { field: 'mac', headerName: t('providers.dialog.bluetooth.name'), width: 145 },
    { field: 'connectivity', headerName: t('providers.dialog.bluetooth.mode'), width: 100 },
    {
      field: 'action', headerName: t('providers.dialog.bluetooth.status'), width: 75, renderCell: function render(params) {
        return <IconButton onClick={ () => params.rowModel.data.gateway_id ? handleDisconnect(params.rowModel.data) : handleConnect(params.rowModel.data) }>
          { params.rowModel.data.gateway_id ? <LinkOffIcon style={ { color: "green" } } /> : <LinkIcon color="error" /> }
        </IconButton>;
      }
    }];

  return (
    <div className={ style.root }>
      <DataGrid autoPageSize headerHeight={ 40 } rowHeight={ 25 } rows={ rows } columns={ columns } pageSize={ 5 } />
    </div>
  );
};
BluetoothDeviceList.propTypes = bluetoothDeviceListPropTypes;

const propTypes = {
  gatewayId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const useBluetoothControlerStyle = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
  }
}));

const BluetoothControler = ({ gatewayId, open, onClose }) => {
  const { t } = useTranslation();
  const style = useBluetoothControlerStyle();
  const [deviceToConnect, setDeviceToConnect] = React.useState(null);
  const bluetoothDevicePair = useBluetoothDevicePair();
  const bluetoothDeviceUnpair = useBluetoothDeviceUnpair();
  const scanOn = useScanOn();

  const handleConnect = (device) => setDeviceToConnect(device);
  const handleDisconnect = (device) => bluetoothDeviceUnpair(gatewayId, device.mac);
  const handleConnectChoise = (connectivity) => (bluetoothDevicePair(gatewayId, deviceToConnect.mac, connectivity), setDeviceToConnect(null));
  const [error, setError] = React.useState(null);
  const sh = React.useRef((message) => setError(message.payload.error_id));
  useWebsocketError(sh.current, gatewayId, 'BLE');

  return (
    <GenericDialog open={ open } onClose={ onClose } title={ t('providers.dialog.bluetooth.title') } aria-labelledby="simple-dialog-title">
      <DialogTitle id="simple-dialog-title">
        <Grid container spacing={ 2 }>
          <Grid xs={ 8 } item>

          </Grid>
          <Grid xs={ 4 } item>
            { error ?
              <Box color="white" bgcolor="palevioletred" p={ 1 }>
                { t('providers.dialog.bluetooth.title') }: { error }
              </Box>
              :
              null
            }
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <BluetoothDeviceList gatewayId={ gatewayId } handleConnect={ handleConnect } handleDisconnect={ handleDisconnect } />
        <Button className={ style.margin } onClick={ () => scanOn(gatewayId) } variant="contained" fullWidth>{ t('providers.dialog.bluetooth.reset') }</Button>
        <GenericDialog title={ t('providers.dialog.bluetooth.choose_connection_mode') } width={ 500 } onClose={ () => setDeviceToConnect(null) } aria-labelledby="simple-dialog-title" open={ deviceToConnect ? true : false }>
          <DialogContent>
            <ButtonGroup fullWidth variant="contained" color="primary">
              <Button onClick={ () => handleConnectChoise('broadcast') }>{ t('providers.dialog.bluetooth.broadcast') }</Button>
              <Button onClick={ () => handleConnectChoise('connect') }>{ t('providers.dialog.bluetooth.connect') }</Button>
            </ButtonGroup>
          </DialogContent>
          <DialogActions></DialogActions>
        </GenericDialog>
      </DialogContent>
      <DialogActions></DialogActions>
    </GenericDialog>);
};

BluetoothControler.propTypes = propTypes;

export default BluetoothControler;
