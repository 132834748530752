import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateDataProviderLabel, updateDataProviderNote, updateDataProviderPosition } from '../../../modules/actions';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}));


const propTypes = {
  open: PropTypes.bool.isRequired,
  initialDataProvider: PropTypes.object,
  handleClose: PropTypes.func
};

const defaultProps = {
  initialCluster: {
    name: '',
    description: ''
  },
  handleClose: () => { }
};




const NumberFormatCustom = (inputRef, onChange, name, ...other) => {
  return (
    <NumberFormat
      { ...other }
      getInputRef={ inputRef }
      onValueChange={ (values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      } }
      thousandSeparator
      isNumericString
      suffix="°"
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const DataProviderEditLabel = ({ initialDataProvider, open, handleClose }) => {
  const { t } = useTranslation();
  const style = useStyles();
  const dispatch = useDispatch();
  const [label, setLabel] = React.useState(initialDataProvider.label.name);
  const handleValidate = () => { dispatch(updateDataProviderLabel(initialDataProvider, label)), handleClose(); };
  const handleOnChange = (event) => setLabel(event.target.value);
  React.useEffect(() => setLabel(initialDataProvider.label.name), [initialDataProvider, open]);
  return (
    <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{ t('providers.provider_detail.label') }</DialogTitle>
      <DialogContent>
        <div className={ style.formContainer }>
          <TextField
            fullWidth
            variant='outlined'
            onChange={ handleOnChange }
            id="standard-error-helper-text"
            label={ t('providers.provider_detail.label') }
            defaultValue={ label }
            error={ !label }
            helperText={ !initialDataProvider.label.name ? t('form.errors.can_not_be_blank') : null } />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleClose } color="primary">
          { t('clusters.edit.cancel') }
        </Button>
        <Button onClick={ handleValidate } color="primary">
          { t('providers.provider_detail.edit') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DataProviderEditPosition = ({ initialDataProvider, open, handleClose }) => {
  const { t } = useTranslation();
  const style = useStyles();
  const dispatch = useDispatch();
  const [lat, setLat] = React.useState(initialDataProvider.lat);
  const [lon, setLon] = React.useState(initialDataProvider.lon);
  const handleValidate = () => { dispatch(updateDataProviderPosition(initialDataProvider, lat, lon)), handleClose(); };
  const handleOnChangeLat = (event) => setLat(event.target.value);
  const handleOnChangeLon = (event) => setLon(event.target.value);
  React.useEffect(() => setLat(initialDataProvider.lat), [initialDataProvider, open]);
  React.useEffect(() => setLon(initialDataProvider.lon), [initialDataProvider, open]);
  return (
    <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{ t('providers.provider_detail.position') }</DialogTitle>
      <DialogContent>
        <div className={ style.formContainer }>
          <TextField
            fullWidth
            variant='outlined'
            onChange={ handleOnChangeLat }
            id="standard-error-helper-text"
            label={ t('providers.provider_detail.latitude') }
            defaultValue={ lat }
            error={ !lat }
            helperText={ !initialDataProvider.lat ? t('form.errors.can_not_be_blank') : null }
            InputProps={ {
              inputComponent: NumberFormatCustom,
            } } />
          <TextField
            fullWidth
            variant='outlined'
            onChange={ handleOnChangeLon }
            id="standard-error-helper-text"
            label={ t('providers.provider_detail.longitude') }
            defaultValue={ lon }
            error={ !lon }
            helperText={ !initialDataProvider.lon ? t('form.errors.can_not_be_blank') : null }
            InputProps={ {
              inputComponent: NumberFormatCustom,
            } } />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleClose } color="primary">
          { t('clusters.edit.cancel') }
        </Button>
        <Button onClick={ handleValidate } color="primary">
          { t('providers.provider_detail.edit') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DataProviderEditNote = ({ initialDataProvider, open, handleClose }) => {
  const { t } = useTranslation();
  const style = useStyles();
  const dispatch = useDispatch();
  const [note, setNote] = React.useState(initialDataProvider.note);
  const handleValidate = () => { dispatch(updateDataProviderNote(initialDataProvider, note)), handleClose(); };
  const handleOnChange = (event) => setNote(event.target.value);
  React.useEffect(() => setNote(initialDataProvider.note), [initialDataProvider, open]);
  return (
    <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{ t('providers.provider_detail.note') }</DialogTitle>
      <DialogContent>
        <div className={ style.formContainer }>
          <TextField
            fullWidth
            variant='outlined'
            onChange={ handleOnChange }
            id="standard-error-helper-text"
            label={ t('providers.provider_detail.note') }
            defaultValue={ note }
            error={ !note }
            helperText={ !initialDataProvider.note ? t('form.errors.can_not_be_blank') : null } />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleClose } color="primary">
          { t('clusters.edit.cancel') }
        </Button>
        <Button onClick={ handleValidate } color="primary">
          { t('providers.provider_detail.edit') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DataProviderEditLabel.propTypes = propTypes;
DataProviderEditLabel.defaultProps = defaultProps;

DataProviderEditPosition.propTypes = propTypes;
DataProviderEditPosition.defaultProps = defaultProps;

DataProviderEditNote.propTypes = propTypes;
DataProviderEditNote.defaultProps = defaultProps;

export {
  DataProviderEditLabel,
  DataProviderEditPosition,
  DataProviderEditNote
};
