import { Paper, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import EventItem from './EventItem';
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    paddingTop: theme.spacing(6)
  },
  title: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    right: theme.spacing(1),
    height: '40px',
    zIndex: 4
  },
  timeline: {
    height: '100%'
  }
}));

const EventsTimelineCard = ({ events }) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const { t } = useTranslation();
  // const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Paper className={classes.paper} style={{ position: 'relative' }} >
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <Typography variant='h6' color='primary' noWrap align='center' className={classes.title}>
          {t('dashboard.eventCard.title')}
        </Typography>
        {events.length === 0 ?
          <Box display="flex" className={classes.timeline} alignItems="center" justifyContent="center">
            <Typography align='center'>
              {t('dashboard.eventCard.empty')}
            </Typography>
          </Box> :
          <Timeline>
            {events.sort((a, b) => a.created_at < b.created_at ? 1 : -1)
              .map((event) => <EventItem key={event.id}
                type={event.event_type}
                subtype={event.event_subtype}
                value={event.new_value}
                detail={event.event_detail}
                date={new Date(event.created_at).toLocaleString(i18n.language)} />)}
          </Timeline>
        }
      </div>
    </Paper >);
};

EventsTimelineCard.propTypes = {
  clusterId: PropTypes.number.isRequired,
  events: PropTypes.array
};

EventsTimelineCard.defaultProps = {};

export default EventsTimelineCard;
