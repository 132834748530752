import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const WIDTH = 50;
const THICKNESS = 2;
const COLOR = "white";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1
  },
  rootLink: {
    width: `${WIDTH}px`,
    left: `${WIDTH / 3}px`,
    bottom: '0px',
    top: '60%',
    position: 'absolute',
    backgroundColor: COLOR,
  },
  verticalLink: {
    width: `${THICKNESS}px`,
    left: `${WIDTH / 3}px`,
    height: '100%',
    position: 'absolute',
    marginTop: -theme.spacing(1),
    backgroundColor: COLOR
  },
  horizontalLink: {
    width: `${WIDTH}px`,
    left: `${WIDTH / 3}px`,
    top: '0px',
    height: theme.spacing(4),
    marginTop: -theme.spacing(1),
    position: 'absolute',
    borderBottom: `${THICKNESS}px solid ${COLOR}`,
    borderBottomColor: COLOR,
    borderLeftStyle: 'solid',
    borderLeftWidth: `${THICKNESS}px`,
    borderLeftColor: COLOR,
    borderRadius: '0 0 0 7px'
  }
}));

const propTypes = {
  lastItem: PropTypes.bool
};

const defaultProps = {
  lastItem: false
};

export const ItemListVerticalLink = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      {!props.lastItem ? <div className={classes.verticalLink} /> : null}
      <div className={classes.horizontalLink} />
    </div>
  );
};

ItemListVerticalLink.propTypes = propTypes;
ItemListVerticalLink.defaultProps = defaultProps;

export const ItemListRootLink = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.rootLink} />
    </div>);
};
