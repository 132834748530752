import * as Actions from '../actions';

const initState = () => {
  return {
    isFetching: false,
    isUpserting: false,
    items: {},
    error: null
  };
};

const updateClusterModel = (cluster) => (
  {
    /* -- minimal data here */
    events: [],
    ...cluster
  }
);

export default (state = initState(), action) => {
  switch (action.type) {
    case Actions.REQUEST_CLUSTERS:
      state = { ...state, isFetching: true };
      break;
    case Actions.RECEIVE_CLUSTERS:
      state = {
        ...state,
        isFetching: false,
        items: action.clusters.reduce((res, cluster) => ({ ...res, [cluster.id]: updateClusterModel(cluster) }), {})
      };
      break;
    case Actions.UPSERT_CLUSTER_BEGIN:
      state = { ...state, isUpserting: true };
      break;
    case Actions.UPSERT_CLUSTER_RECEIVED:
      state = {
        ...state,
        items: {
          ...state.items,
          [action.cluster.id]: updateClusterModel(action.cluster)
        },
        isUpserting: false
      };
      break;
    // =======================
    // == Data Provider Events
    // =======================
    case Actions.REQUEST_DP_EVENTS_DOCUMENT:
      break;
    case Actions.RECEIVE_DP_EVENTS_DOCUMENT:
      if (!state.items[action.clusterId]) {
        console.error('no found cluster');
        break;
      }
      state.items[action.clusterId].events = action.eventsDocuments.events;
      state = {
        ...state,
        items: {
          ...state.items,
          [action.clusterId]: updateClusterModel(state.items[action.clusterId])
        },
      };
      break;
    // ========
    // == ERROR
    // ========
    case Actions.CLUSTER_API_ERROR:
      console.warn(action.error);
      state = {
        ...state,
        error: action.error
      };
      break;
    default:
      break;
  }
  return state;
};
