import React from 'react';
import mapboxgl from 'mapbox-gl';
import template from '../../../template/DataProviderMarkerTemplate.html';
import PropTypes from 'prop-types';

const MapMarker = ({map, icon, lon, lat, children}) => {
  const marker = React.useRef(null);
  const [newChildren, setNewChildren] = React.useState([]);

  React.useEffect(() => {
    const el = document.createElement('div');
    el.innerHTML = template({icon});
    marker.current = new mapboxgl.Marker(el, {
      draggable: false
    })
      .setLngLat([lon, lat])
      .addTo(map);

    el.addEventListener('click', () => {
      map.easeTo({
        center: [lon, lat + ((map.getBounds().getNorth() - map.getBounds().getSouth()) / 3)],
        speed: 0.2
      });
    });

    return () => {
      marker.current.remove();
      marker.current = null;
    };
  }, [map]);

  React.useEffect(() => {
    if (marker.current) {
      const res = React.Children.map(children, child => {
        // Process MapPopup
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map, marker: marker.current });
        }
        else {
          return child;
        }
      });
      setNewChildren(res);
    }
  }, [map.current, children]);

  if (marker.current === null)
    return null;
  
  return newChildren;
};

const propTypes = {
  map: PropTypes.any,
  icon: PropTypes.string,
  lon: PropTypes.number,
  lat: PropTypes.number,
  children: PropTypes.element
};

MapMarker.propTypes = propTypes;

export default MapMarker;
