import * as Actions from '../actions';

const initState = () => {
  return {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    items: {},
    errors: []
  };
};

export default (state = initState(), action) => {
  switch (action.type) {
    case Actions.REQUEST_API_CLUSTER_EVENT_CONFIGURATIONS:
      state = {
        ...state,
        isFetching: action.status
      };
      break;
    case Actions.RECEIVE_API_CLUSTER_EVENT_CONFIGURATIONS:
      state = {
        ...state,
        items: {
          ...state.items,
          [action.clusterId]: action.clusterEventConfigurations
        }
      };
      break;
    case Actions.CREATE_API_CLUSTER_EVENT_CONFIGURATION:
      state = { ...state, isCreating: action.status };
      break;
    case Actions.UPDATE_API_CLUSTER_EVENT_CONFIGURATION:
      state = { ...state, isUpdating: action.status };
      break;
    case Actions.SUCCESS_CREATE_API_CLUSTER_EVENT_CONFIGURATION:
    case Actions.SUCCESS_UPDATE_API_CLUSTER_EVENT_CONFIGURATION:
      if (!state.items[action.clusterId]) {
        console.error('no found cluster');
        break;
      }
      var idxToCreate = state.items[action.clusterId].findIndex(eventConfig => action.clusterEventConfiguration.id === eventConfig.id);
      if (idxToCreate >= 0)
        state.items[action.clusterId][idxToCreate] = action.clusterEventConfiguration;
      else
        state.items[action.clusterId].push(action.clusterEventConfiguration);
      state = {
        ...state,
        items: {
          ...state.items,
          [action.clusterId]: [...state.items[action.clusterId]]
        }
      };
      break;
    case Actions.DELETE_API_CLUSTER_EVENT_CONFIGURATION:
      state = { ...state, isDeleting: action.status };
      break;
    case Actions.SUCCESS_DELETE_API_CLUSTER_EVENT_CONFIGURATION:
      if (!state.items[action.clusterId]) {
        console.error('no found cluster');
        break;
      }
      state = {
        ...state,
        items: {
          ...state.items,
          [action.clusterId]: state.items[action.clusterId].filter(ec => ec.id !== action.eventConfigId)
        }
      };
      break;
    case Actions.RECEIVE_API_EVENT_CONFIGURATIONS_ERROR:
      state = {
        ...state,
        errors: [action.error, ...state.errors]
      };
  }
  return state;
};
