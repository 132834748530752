import { Breadcrumbs, Checkbox, Container, FormControlLabel, Grid, Link, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import AbstractAppbar from '../../../components/appbar/AbstractAppbar';
import { Spinner, LinearProgressWithLabel, RangeDatePicker } from '../../../components/lib';
import { useDataProvider, useDataServiceByDataProvider, useFetchDataService } from '../../../modules/hooks';
import AbstractMainView from '../AbstractMainView';
import DataProviderCard from '../../../components/clusterDetail/dataProviderDetail/DataProviderCard';
import { DataServiceChart, DataServiceChip } from '../../../components/shared/dataService';

const dataProviderIdKey = 'dataProviderId';
const useDataProviderIdParams = () => parseInt(useParams()[dataProviderIdKey]);

const TODAY = new Date(new Date().toISOString().split('T')[0]);

const useStyles = makeStyles((theme) => ({
  root: {

    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%'
  }
}));

const ProviderDetailView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dataProviderId = useDataProviderIdParams();
  const fetchDataService = useFetchDataService();
  const [{ from, to }, setDates] = React.useState({ from: TODAY, to: TODAY });
  const [progress, setProgress] = React.useState(100);
  const [beginAtZero, setBeginAtZero] = React.useState(false);

  const dataProvider = useDataProvider(dataProviderId);
  const dataServices = useDataServiceByDataProvider(dataProviderId);
  const [scratchDataServiceIDs, setScratchDataServiceIDs] = React.useState([]);
  const handleServiceClick = React.useCallback(
    (dataService) => {
      if (scratchDataServiceIDs.includes(dataService.id))
        setScratchDataServiceIDs([...scratchDataServiceIDs.filter(v => v !== dataService.id)]);
      else
        setScratchDataServiceIDs([...scratchDataServiceIDs, dataService.id]);
    }
  );

  React.useEffect(() => fetchDataService(dataProviderId), [dataProviderId]);

  const handleDates = (dates) => setDates(dates);
  if (!dataProvider)
    return t("providers.provider_detail.loading");
  return (
    <AbstractMainView
      appBar={
        <AbstractAppbar
          leftAction={
            <Breadcrumbs>
              <Link color="inherit" href={ `#/cluster/${dataProvider.cluster_id}/providers` }>
                Cluster
              </Link>
              <Typography color="textPrimary">{ t("providers.provider_detail.equipment") }</Typography>
            </Breadcrumbs>
          }
        /> }
      app={
        <Container className={ classes.root }>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <DataProviderCard dataProvider={ dataProvider } />
            </Grid>
            <Grid item container spacing={ 2 }>
              <Grid item xs={ 12 } lg={ 3 }>
                <Paper className={ classes.paper }>
                  <Typography variant="h6" color="primary" noWrap>{ t("providers.provider_detail.services") }</Typography>
                  { dataServices
                    .sort((s1, s2) => ((s1.type + s1.channel) < (s2.type + s2.channel)) ? -1 : 1)
                    .map(service => {
                      return (
                        <React.Fragment key={ service.id }>
                          <DataServiceChip
                            onClick={ () => handleServiceClick(service) }
                            dataService={ service }
                            scratch={ scratchDataServiceIDs.includes(service.id) } />
                          <br />
                        </React.Fragment>);
                    }) }
                </Paper>
              </Grid>
              <Grid item xs={ 12 } lg={ 9 }>
                <Paper className={ classes.paper }>
                  <Typography variant="h6" color="primary" noWrap>{ t("providers.provider_detail.data") }</Typography>
                  <div style={ { marginTop: '10px', marginBottom: '30px' } }>
                    <RangeDatePicker
                      disabled={ progress < 100 }
                      onChange={ handleDates } />

                  </div>
                  <div style={ { position: "relative", height: '256px' } }>
                    { progress < 100 ? (<Spinner />) : null }
                    <DataServiceChart
                      dataServices={ dataServices }
                      hiddenDataServiceIDs={ scratchDataServiceIDs }
                      dateFrom={ from }
                      dateTo={ to }
                      onProgress={ (progress) => setProgress(progress) }
                      beginAtZero={ beginAtZero } />
                  </div>
                  <LinearProgressWithLabel value={ progress } />
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={ (event) => setBeginAtZero(event.target.checked) }
                          color="primary"
                          inputProps={ { 'aria-label': 'secondary checkbox' } }
                        /> }
                      label="begin at zero"
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Container >
      }
    />
  );
};

export default ProviderDetailView;
