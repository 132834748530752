import { Box, CircularProgress, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetImageCluster, useUpsertCluster, useUpsertImageCluster, useClusterContext } from '../../../modules/moduleCluster/hooks';
import { Refresh as RefreshIcon, AddPhotoAlternate as AddPhotoAlternateIcon, Edit as EditIcon } from '@material-ui/icons';
import ClusterEditDialog from '../../shared/ClusterEditDialog';
import { DropzoneDialog } from 'material-ui-dropzone';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: theme.spacing(2)
  },
  media: {
    position: 'relative',
    width: '110px',
    height: '110px',
    '& img': {
      width: '110px',
      height: '110px',
      borderRadius: '10%',
    }

  },
  floatingStatus: (cluster) => ({
    zIndex: 20,
    position: "absolute",
    backgroundColor: theme.palette[cluster.enable ? 'success' : 'error'].light,
    color: theme.palette[cluster.enable ? 'success' : 'error'].contrastText,
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(0.1),
    paddingBottom: theme.spacing(0.1),
    bottom: '5px',
    right: '-5px',
    minWidth: theme.spacing(8)
  }),
  floatingActions: () => ({
    zIndex: 20,
    position: "absolute",
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(0.1),
    paddingBottom: theme.spacing(0.1),
    bottom: '5px',
    right: '5px',
    minWidth: theme.spacing(8)
  }),
}));

const propTypes = {};

const ClusterTitleCard = () => {
  const cluster = useClusterContext();
  const classes = useStyles(cluster);
  const { t } = useTranslation();
  const [image, isLoading] = useGetImageCluster(cluster);
  const [[openEdit, openImageDropzone], setOpenEdit] = React.useState([false, false]);

  const upsertCluster = useUpsertCluster();
  const upsertImageCluster = useUpsertImageCluster();

  const onSaveClusterEdit = (c) => { upsertCluster(c); setOpenEdit([false, false]); };
  const onSaveClusterImageUpdate = (files) => { upsertImageCluster(cluster, files[0]); setOpenEdit([false, false]); };

  return (
    <React.Fragment>
      <Grid container className={classes.root}>
        <Grid xs={4} md={2} item>
          <Box display="flex" className={classes.media} alignItems="center" justifyContent="center">
            {isLoading ? <CircularProgress /> :
              <img src={image} title={cluster.name} />
            }
            <Paper fontWeight='bold' color='success' variant='elevation' className={classes.floatingStatus} elevation={0}>
              <Typography variant="body2" align='center'>
                <strong><span>{cluster.enable ? t('clusters.card.active') : t('clusters.card.inactive')}</span></strong>
              </Typography>
            </Paper>
          </Box>
        </Grid>
        <Grid xs={8} md={10} item>
          <Typography
            align='center'
            variant="h4"
            color="textSecondary"
            title={cluster.description}>
            {cluster.name}
          </Typography>
          <Typography
            align='center'
            paragraph
            variant="caption"
            color="textSecondary"
            title={cluster.description}>
            {cluster.description || t('dashboard.no_description')}
          </Typography>
        </Grid>
        <Box className={classes.floatingActions}>
          <IconButton onClick={() => setOpenEdit([true, false])} size='small' color='secondary'><EditIcon /></IconButton>
          <IconButton onClick={() => setOpenEdit([false, true])} size='small' color='secondary'><AddPhotoAlternateIcon /></IconButton>
          <IconButton onClick={() => setOpenEdit([true, false])} size='small' color='secondary'><RefreshIcon /></IconButton>
        </Box>
      </Grid>
      <ClusterEditDialog
        initialCluster={cluster}
        open={openEdit}
        onClose={() => setOpenEdit([false, false])}
        onSave={onSaveClusterEdit} />
      <DropzoneDialog
        filesLimit={1}
        open={openImageDropzone}
        onClose={() => setOpenEdit([false, false])}
        onSave={onSaveClusterImageUpdate} />
    </React.Fragment >
  );
};

ClusterTitleCard.propTypes = propTypes;

export default ClusterTitleCard;
