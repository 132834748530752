import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';

const MapPopup = ({marker, closeOnClick, closeOnMove, focusAfterOpen, ...props}) => {
  const popup = React.useRef(null);
  const popupContainer = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    popupContainer.current = document.createElement('div');
    popup.current = new mapboxgl.Popup({
      // anchor: 'bottom',
      offset: 25,
      maxWidth: '500px',
      closeOnClick,
      closeOnMove,
      focusAfterOpen
    }).setDOMContent(popupContainer.current);
    popup.current.on('open', () => {
      setOpen(true);
    });
    popup.current.on('close', () => setOpen(false));
    marker.setPopup(popup.current);
  }, []);

  if (!open)
    return null;
  return (
    ReactDOM.createPortal(
      <div {...props}/>,
      popupContainer.current)
  );
};

const propTypes = {
  marker: PropTypes.any
};

const defaultProps = {
  closeOnClick: false,
  closeOnMove: false,
  focusAfterOpen: false
};

MapPopup.propTypes = propTypes;
MapPopup.defaultProps = defaultProps;

export default MapPopup;
