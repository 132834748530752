import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

export const SpinnerV2 = ({ children, isLoading }) => {
  if (!isLoading)
    return children;
  return (
    <Box>
      <CircularProgress />
    </Box>);
};

SpinnerV2.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node
};

