import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { useClusterContext, useDataProvidersByCluster } from '../../../../modules/hooks';
import DataProviderMapModalSearch from './DataProviderMapModalSearch';
import DataProviderMap from './DataProviderMap';

const DataProviderMapCard = () => {
  const map = React.useRef(null);

  const cluster = useClusterContext();
  const dataProviders = useDataProvidersByCluster(cluster.id);

  const handleDpListClick = (dp) => {
    if (map.current) {
      map.current.flyTo({ center: [dp.lon, dp.lat], zoom: 18 });
    }
  };

  return (
    <Box component={Paper} padding={1} color='secondary' style={{ height: '100%', position: 'relative' }}>
      <DataProviderMap
        mapRef={map}
        dataProviders={dataProviders}/>
      <DataProviderMapModalSearch
        onDataProviderClicked={handleDpListClick}
        dataProviders={dataProviders}/>
    </Box >
  );
};

export default DataProviderMapCard;
