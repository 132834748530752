import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCluster, fetchClusterDpEventsDocuments, updateImageCluster, upsertCluster } from './actions';
import { clusterApi } from '../../modules/api';
import * as s from './selectors';
import { useFetcher } from '../../libs/utilHooks';

// ===========
// Use cluster
// ===========

export const ClusterContext = React.createContext(null);

// =====
// Store
// =====

export const useClusterContext = () => {
  const cluster = React.useContext(ClusterContext);
  if (!cluster)
    throw new Error('No cluster context');
  return cluster;
};

export const useCluster = (clusterId) => {
  return useSelector(state => s.clusterMapSelector(state)[clusterId]);
};

export const useClustersIsFetching = () => {
  return useSelector(state => state.clusters.isFetching);
};

export const useClusterMap = () => {
  return useSelector(s.clusterMapSelector);
};

export const useClusterList = () => {
  return useSelector(s.clusterListSelector);
};

export const useUpsertCluster = () => {
  const dispatch = useDispatch();
  return (cluster) => dispatch(upsertCluster(cluster));
};

export const useUpsertImageCluster = () => {
  const dispatch = useDispatch();
  return (cluster, file) => dispatch(updateImageCluster(cluster, file));
};

// ===============
// Fetch and Store
// ===============

export const useFetchCluster = () => {
  const dispatch = useDispatch();
  return () => dispatch(fetchCluster());
};

export const useFetchClusterEventsDocument = () => {
  const dispatch = useDispatch();
  return (cluster) => dispatch(fetchClusterDpEventsDocuments(cluster));
};

// =====
// Fetch
// =====

export const useGetImageCluster = (cluster) => {
  return useFetcher(() => clusterApi.fetchImageCluster(cluster), [cluster]);
};
