import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthRedirect } from '../actions';
import { changeNavTitle } from '../moduleNav/actions';
import { isConnectedSelector } from './selectors';

export const useNavTitle = () => useSelector(state => state.nav.navTitle);

export const useSetNavTitle = (title) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(changeNavTitle(title));
  }, [title, dispatch]);
};


export const useIsConnected = () => {
  return useSelector(isConnectedSelector);
};

export const useToken = () => {
  return useSelector(state => state.auth.token);
};

export const useIsFetchingUser = () => {
  return useSelector(state => state.auth.isFetchingUser);
};

export const useAuthUser = () => {
  return useSelector(state => state.auth.user);
};

export const useAuthRedirect = () => {
  const dispatch = useDispatch();
  const authRedirect = useSelector(state => state.auth.authRedirect);
  return [authRedirect, (newRedirect) => dispatch(setAuthRedirect(newRedirect))];
};
