import { ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_ERROR_SNACKBAR } from './actions';

const defaultState = {
  notificationsSuccess: [],
  notificationsError: [],
  notificationsInfo: [],
  notificationsWarning: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ENQUEUE_SUCCESS_SNACKBAR:
      return {
        ...state,
        notificationsSuccess: [
          action.notification,
          ...state.notificationsSuccess,
        ],
      };
    case ENQUEUE_ERROR_SNACKBAR:
      return {
        ...state,
        notificationsError: [
          action.notification,
          ...state.notificationsError,
        ],
      };
    default:
      return state;
  }
};
