import React from 'react';
import { useDispatch } from 'react-redux';
import { GenericList, GenericListItem, GenericDialog } from '../lib';
import { useAuthUser } from '../../modules/hooks';
import { Box, Button, Link, TextField } from '@material-ui/core';
import { registerNotificationEmail, unregisterNotificationEmail, toggleNotificationEmail, verifyNotificationEmail } from '../../modules/moduleAuth/actions';

export default function AdditionalEmailList() {
  const auth = useAuthUser();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const [email, setEmail] = React.useState(false);

  const handleRegisterNotificationEmail = () => {
    dispatch(registerNotificationEmail(email));
    setIsOpen(false);
  };

  const handleUnregisterNotificationEmail = (id) => {
    dispatch(unregisterNotificationEmail(id));
  };

  const handleToggleNotificationEmail = (id, status) => {
    dispatch(toggleNotificationEmail(id, status));
  };

  const handleVerifyNotificationEmail = (email) => {
    dispatch(verifyNotificationEmail(email));
  };


  return (
    <Box>
      <GenericList
        title='Available emails:'
        onAdd={ () => setIsOpen(true) }
        emptyMessage='No registered email'>
        { auth.email_notifications.sort((a, b) => a.email > b.email ? 1 : -1).map((emailNotification) =>
          <GenericListItem
            key={ emailNotification.id }
            title={ emailNotification.email }
            subtitle={ emailNotification.validated ? "Verified" : <span>Unverified <Link component="button" variant="body2" onClick={ () => handleVerifyNotificationEmail(emailNotification.email) } color="primary"> send email </Link></span> }
            onNotifications={ () => handleToggleNotificationEmail(emailNotification.id, emailNotification.activated) }
            onDelete={ () => handleUnregisterNotificationEmail(emailNotification.id) }
            notificationStatus={ emailNotification.activated }
          >
            <p>validate: { emailNotification.validated } activated: { emailNotification.activated }</p>
          </GenericListItem>
        ) }
      </GenericList>
      <GenericDialog
        title="Add email"
        open={ isOpen }
        onClose={ () => setIsOpen(false) }
        actions={ <Button type="submit" onClick={ handleRegisterNotificationEmail } variant="outlined" color="primary">validate</Button> }
      >
        <TextField onChange={ e => setEmail(e.target.value) } required label="email" variant="outlined" />
      </GenericDialog>
    </Box >

  );
}
