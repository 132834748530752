import { isToday } from 'date-fns';
import * as Actions from '../actions';

const initState = () => {
  return {
    isFetching: false,
    isUpdating: false,
    gateways: {},
    devices: {},
    autonomousDevices: {},
    scannedBluetoothDevicesByGatewayId: {},
    dataProviderErrors: {},
    dataProviderErrorsTodayLength: {},
    error: null
  };
};



export default (state = initState(), action) => {
  switch (action.type) {

    // ========
    // GENERIC
    // ========
    case Actions.UPDATE_DATA_PROVIDER_BEGIN:
      state = { ...state, isUpdating: true };
      break;
    case Actions.UPDATE_DATA_PROVIDER_RECEIVED:
      var dataProviderType = action.dataProvider.provider_type + 's';

      state = { ...state, isUpdating: false };

      state = {
        ...state,
        [dataProviderType]: {
          ...state[dataProviderType],
          [action.dataProvider.id]: {
            ...state[dataProviderType][action.dataProvider.id],
            ...action.dataProvider
          }
        }
      };
      break;
    // ========
    // GATEWAYS
    // ========
    case Actions.REQUEST_GATEWAYS:
      state = { ...state, isFetching: true };
      break;
    case Actions.RECEIVE_GATEWAYS:
      state = {
        ...state,
        isFetching: false,
        gateways: action.gateways.reduce((res, gateway) => {
          res[gateway.id] = gateway;
          return res;
        }, {})
      };
      break;
    // =======
    // DEVICES
    // =======
    case Actions.REQUEST_DEVICES:
      state = { ...state, isFetching: true };
      break;
    case Actions.RECEIVE_DEVICES:
      state = {
        ...state,
        isFetching: false,
        devices: action.devices.reduce((res, device) => {
          res[device.id] = device;
          return res;
        }, {})
      };
      break;
    case Actions.UPDATE_BLUETOOTH_DEVICE_PAIR:
      state.devices[action.message.payload.id] = action.message.payload;
      state = { ...state, devices: { ...state.devices } };
      break;
    case Actions.DESTROY_BLUETOOTH_DEVICE_PAIR:
      delete state.devices[action.message.payload.id];
      state = { ...state, devices: { ...state.devices } };
      break;
    case Actions.UPDATE_BLUETOOTH_SCANNED_DEVICES:
      state = {
        ...state,
        scannedBluetoothDevicesByGatewayId: {
          ...state.scannedBluetoothDevicesByGatewayId,
          [action.message.id]: action.message.payload.devices
        }
      };
      break;
    // =================
    // AUTONOMOUS_DEVICE
    // =================
    case Actions.REQUEST_AUTONOMOUS_DEVICES:
      state = { ...state, isFetching: true };
      break;
    case Actions.RECEIVE_AUTONOMOUS_DEVICES:
      state = {
        ...state,
        isFetching: false,
        autonomousDevices: action.autonomousDevices.reduce((res, autonomousDevice) => {
          res[autonomousDevice.id] = autonomousDevice;
          return res;
        }, {})
      };
      break;
    // =======================
    // DATA_PROVIDER_ERRORS
    // =======================
    case Actions.REQUEST_DP_ERRORS:
      break;
    case Actions.RECEIVE_DP_ERRORS:
      state = {
        ...state,
        dataProviderErrors: {
          ...state.dataProviderErrors,
          [action.dataProviderId]: action.dpErrors
        },
        dataProviderErrorsTodayLength: {
          ...state.dataProviderErrorsTodayLength,
          [action.dataProviderId]: action.dpErrors.filter(error => isToday(new Date(error.date))).length
        }
      };
      break;
    // =======================
    // DATA_PROVIDER_API_ERROR
    // =======================
    case Actions.DATA_PROVIDER_API_ERROR:
      console.warn(action.error);
      state = {
        ...state,
        error: action.error
      };
      break;
    default:
      break;
  }
  return state;
};
