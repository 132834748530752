import { Box, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GenericList, GenericListItem } from '../../../lib';
import { useClusterContext } from '../../../../modules/moduleCluster/hooks';
import { useClusterEventConfigurations, useCreateClusterEventConfigurationsCallback, useDeleteClusterEventConfigurationsCallback, useUpdateClusterEventConfigurationsCallback } from '../../../../modules/moduleClusterEventConfigurations/hooks';
import EventEditDialog from './EventEditDialog';

const getOperator = (operator) => {
  const operatorMap = {
    'all': '*',
    'lower': '<',
    'higher': '>',
    'equal': '=',
    'not-equal': '≠',
  };
  if (Object.keys(operatorMap).includes(operator)) return operatorMap[operator];
  return operator;
};

const propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

const defaultProps = {};

const EventsList = ({ onEdit, onDelete }) => {
  const { t } = useTranslation();
  const cluster = useClusterContext();
  const [editEvent, setEditEvent] = React.useState();

  const clusterEventConfigurations = useClusterEventConfigurations(cluster.id);
  const createClusterEventConfigurations = useCreateClusterEventConfigurationsCallback();
  const updateClusterEventConfigurations = useUpdateClusterEventConfigurationsCallback();
  const deleteClusterEventConfigurations = useDeleteClusterEventConfigurationsCallback();

  const handleAdd = () => setEditEvent({ cluster: cluster.id });
  const handleEdit = (event) => onEdit ? onEdit() : setEditEvent(event);
  const handleDelete = (event) => onDelete ? onDelete() : deleteClusterEventConfigurations(event.cluster, event);
  const handleSave = (eventConfig) => !eventConfig.id ?
    createClusterEventConfigurations(cluster.id, eventConfig) :
    updateClusterEventConfigurations(cluster.id, eventConfig);

  return (
    <Box>
      <GenericList
        dense
        title={t('clusters.event.list.title')}
        onAdd={handleAdd}>
        {clusterEventConfigurations.map(eventConf =>
          <GenericListItem
            key={eventConf.id}
            title={`${eventConf.name} (${eventConf.event_type} ${getOperator(eventConf.operator)} ${eventConf.value})`}
            subtitle={`${eventConf.data_services.length} services link`}
            onEdit={() => handleEdit(eventConf)}
            onDelete={() => handleDelete(eventConf)} >
            <Chip label={``} color="primary" size="small" />
          </GenericListItem>
        )}
      </GenericList>
      <EventEditDialog open={Boolean(editEvent)} onSave={handleSave} onClose={() => setEditEvent(undefined)} initialEvent={editEvent} />
    </Box>
  );
};

EventsList.propTypes = propTypes;
EventsList.defaultProps = defaultProps;

export default EventsList;
