import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EventsList from '../../../components/clusterDetail/configuration/EventsList';
import AbstractClusterView from './AbstractClusterView';

const ConfigurationView = () => {
  const { t } = useTranslation();
  return (
    <AbstractClusterView
      title={ t("clusters.configuration.title") }
      app={
        <Container>
          <Grid item xs={ 12 }>
            <EventsList disablePadding />
          </Grid>
        </Container>
      }
    />);
};
export default ConfigurationView;
