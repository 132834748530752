import {
  AppBar,
  Box,
  Button, ButtonGroup, Grid,
  IconButton,
  List, ListItem, ListItemSecondaryAction, ListItemText, Tab, Tabs, Typography
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LockOpenOutlined, LockOutlined, PhotoCamera, VolumeOff, VolumeUp } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Image from 'material-ui-image';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { dataRenderFormater } from '../../../libs/dataValueHelper';
import { DataServiceCommandHooks, IS_NONE, useClusterContext, useClusterEventConfigurations, useDataLastValue, useFetchData, useFetchDataImage, useLinkClusterEventConfigurationsToDataServiceCallback, useUnlinkClusterEventConfigurationsToDataServiceCallback } from '../../../modules/hooks';
import { GenericDialog } from '../../lib';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogContent-root": {
      padding: 0,
    },
    "& .MuiPaper-root.MuiDialog-paper": {
      height: "80vh",
      minWidth: "345px",
    }
  },
  button: {
    marginTop: theme.spacing(1)
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

const customSectionPropTypes = {
  dataService: PropTypes.object.isRequired,
  lastValue: PropTypes.any
};

const PictureSectionRender = ({ dataService, lastValue }) => {
  const { t } = useTranslation();
  const imageName = lastValue ? lastValue.value[1] : '';
  const [sendTakePicture, takePictureStatus] = DataServiceCommandHooks.useTakePicture();
  const [uri] = useFetchDataImage(imageName);

  return (
    <React.Fragment>
      <Image
        cover
        aspectRatio={16 / 12}
        src={uri}
      />
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        disabled={takePictureStatus != IS_NONE}
        onClick={() => sendTakePicture(dataService.id)}
        startIcon={<PhotoCamera />}
      >{t('providers.dialog.provider.take_picture')}</Button>
    </React.Fragment>);
};

const BuzzSectionRender = ({ dataService, /* lastValue */ }) => {
  const { t } = useTranslation();
  const [sendBuzz, /* status */] = DataServiceCommandHooks.useSetBuzz();
  return (
    <ButtonGroup
      variant="outlined"
      fullWidth
      orientation="vertical"
      color="primary"
      aria-label="vertical outlined primary button group"
    >
      <Button
        color="primary"
        onClick={() => (sendBuzz(dataService.id, true, 10))}
        startIcon={<VolumeUp />}
      >{t('providers.dialog.provider.start_buzz')}
      </Button>
      <Button
        color="primary"
        onClick={() => (sendBuzz(dataService.id, false, 10))}
        startIcon={<VolumeOff />}
      >{t('providers.dialog.provider.stop_buzz')}</Button>
    </ButtonGroup>);
};

/* eslint-disable */
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
/* eslint-enable */

const DigitalOutputSectionRender = ({ dataService, /* lastValue */ }) => {
  const { t } = useTranslation();
  const [sendDigitalOutput] = DataServiceCommandHooks.useSetDigitalOutput();
  return (
    <ButtonGroup
      variant="outlined"
      fullWidth
      orientation="vertical"
      color="primary"
      aria-label="vertical outlined primary button group"
    >
      <Button
        color="primary"
        onClick={() => (sendDigitalOutput(dataService.id, true, 10))}
        startIcon={<LockOpenOutlined />}
      >{t('providers.dialog.provider.open_do')}
      </Button>
      <Button
        color="primary"
        onClick={() => (sendDigitalOutput(dataService.id, false, 10))}
        startIcon={<LockOutlined />}
      >{t('providers.dialog.provider.close_do')}</Button>
    </ButtonGroup>);
};

PictureSectionRender.propTypes = customSectionPropTypes;
BuzzSectionRender.propTypes = customSectionPropTypes;
DigitalOutputSectionRender.propTypes = customSectionPropTypes;

const propTypes = {
  dataService: PropTypes.object.isRequired,
  open: PropTypes.bool
};

const DataServiceDialog = ({ dataService, ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const [data, fetchData] = useFetchData(dataService);
  const dataLastValue = useDataLastValue(dataService.id);
  const linkClusterEventConfigurationsToDataServiceCallback = useLinkClusterEventConfigurationsToDataServiceCallback();
  const unlinkClusterEventConfigurationsToDataServiceCallback = useUnlinkClusterEventConfigurationsToDataServiceCallback();

  // Fetch all data only on dataLastValue trigger
  React.useEffect(() => {
    if (props.open) fetchData();
  }, [dataLastValue, props.open]);

  const cluster = useClusterContext();
  const allEvents = useClusterEventConfigurations(cluster.id).filter(event => event.event_type === dataService.type);
  const linkedEvents = React.useMemo(() => allEvents.filter(event => event.data_services.includes(dataService.id)), [cluster.dataserviceEventConfigurations]);
  let CustomSection = null;
  switch (dataService.type) {
    case 'image':
      CustomSection = PictureSectionRender;
      break;
    case 'buzz':
      CustomSection = BuzzSectionRender;
      break;
    case 'digital-output':
      CustomSection = DigitalOutputSectionRender;
      break;
  }

  const { formatedData, friendlyDateString } = dataRenderFormater(dataService.type, dataLastValue, 'fr-FR');

  const handleLink = (event) => {
    if (event.data_services.includes(dataService.id))
      unlinkClusterEventConfigurationsToDataServiceCallback(cluster.id, event.id, dataService.id);
    else
      linkClusterEventConfigurationsToDataServiceCallback(cluster.id, event.id, dataService.id);
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // eslint-disable-next-line react/prop-types
  const InfoItem = ({ label, value }) => (
    <ListItem>
      <Grid container>
        <Grid item xs="auto" md={5}>
          <Typography variant="body2" color="textSecondary" component="p">
            {`${label} :`}&nbsp;
          </Typography>
        </Grid>
        <Grid item xs="auto" md={7}>
          <Typography variant="body2" color="textSecondary" component="p">
            {value}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );

  return (
    <GenericDialog {...props} className={classes.root} onInNew={`/cluster/${cluster.id}/provider/${dataService.data_provider_id}/service/${dataService.id}`}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example">
          <Tab label={t('providers.dialog.provider.informations')} id={`full-width-tab-0`} />
          <Tab label={t('providers.dialog.provider.events')} id={`full-width-tab-1`} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex} >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Grid container>
            <Grid item xs={12}>
              <List dense>
                <InfoItem label={t('providers.dialog.provider.today_data')} value={data.length} />
                <InfoItem label={t('providers.dialog.provider.last_value')} value={formatedData} />
                <InfoItem label={t('providers.dialog.provider.date')} value={friendlyDateString} />
                {CustomSection ? (
                  <ListItem>
                    <ListItemText>
                      <CustomSection dataService={dataService} lastValue={dataLastValue} />
                    </ListItemText>
                  </ListItem>
                ) : null}
              </List>
            </Grid>
            <Grid item xs={12}>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Typography variant="body2" color="textSecondary" component="p">
            {linkedEvents.length} {t('providers.dialog.provider.configured_event')} {allEvents.length}
          </Typography>
          <List dense>
            {allEvents.map(event => (
              <ListItem key={event.id}>
                <ListItemText
                  primary={`Event: ${event.name}`}
                  secondary={`${event.operator} ${event.value}`} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleLink(event)}>
                    {event.data_services.includes(dataService.id) ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>))}
          </List>
        </TabPanel>
      </SwipeableViews>
    </GenericDialog>
  );
};

DataServiceDialog.propTypes = propTypes;

export default DataServiceDialog;
