import React from 'react';
import AbstractClusterView from './AbstractClusterView';
import EditableScenarioForm from '../../../components/clusterDetail/dataProviderDetail/falcon/EditableScenarioForm';
import { useParams } from "react-router-dom";
import { /* useDataProvider, */ useDataProvider, useGetScenarioCommand } from '../../../modules/hooks';
import { Container } from '@material-ui/core';
import { gatewayApi } from '../../../modules/api';

const dataProviderIdKey = 'dataProviderId';
const useDataProviderIdParams = () => parseInt(useParams()[dataProviderIdKey]);

const ProviderScenarioView = () => {
  const [scenarioDocument, setScenarioDocument] = React.useState(null);
  // const [isFetching, setIsFetching] = React.useState(null);
  const dataProviderId = useDataProviderIdParams();
  const dataProvider = useDataProvider(dataProviderId);
  const getScenarioCommand = useGetScenarioCommand();
  React.useEffect(() => {
    if (!dataProvider)
      return;
    getScenarioCommand(dataProvider)
      .then(
        () => gatewayApi.fetchScenarios(dataProviderId)
          .then((scenarioDocument) => setScenarioDocument(scenarioDocument))
          .catch()
      );
  }, [dataProviderId, dataProvider]);
  return (
    <AbstractClusterView
      app={
        <Container>
          { scenarioDocument ? scenarioDocument.scenarios.map((scenario, index) => <EditableScenarioForm key={ index } falconProvider={ dataProvider } scenario={ scenario } />) : undefined }
        </Container>
      }
      title='Scenarios'
      rightAction={ "" }
    />);
};
export default ProviderScenarioView;
