import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Star as FavoriteIconSelected,
  StarBorder as FavoriteIconNotSelected,
  BluetoothSearching as BluetoothSearchingIcon,
  AssessmentOutlined as MoreIcon
} from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useAuthUser } from '../../../modules/hooks';
import { updateDataProviderLabelFavoris } from '../../../modules/actions';

const propTypes = {
  dataProvider: PropTypes.object.isRequired,
  onBluetoothClick: PropTypes.func,
  displayMore: PropTypes.bool,
};

const defaultProps = {
  onFavorisClick: () => { },
  onBluetoothClick: null,
  displayMore: true
};

const ActionButtons = ({ displayMore, dataProvider, onBluetoothClick }) => {
  const user = useAuthUser();
  const dispatch = useDispatch();

  const isFavorite = user.favoris.data_provider_labels.includes(dataProvider.id);
  const handleFavoris = () => {
    let dataProviderLabelsFavoris = user.favoris.data_provider_labels;
    if (dataProviderLabelsFavoris.includes(dataProvider.id)) {
      dataProviderLabelsFavoris = dataProviderLabelsFavoris.filter(item => item !== dataProvider.id);
    }
    else {
      dataProviderLabelsFavoris.push(dataProvider.id);
    }
    dispatch(updateDataProviderLabelFavoris(dataProviderLabelsFavoris));
  };
  return (
    <React.Fragment>
      <IconButton onClick={handleFavoris} size='small' color='secondary'>{isFavorite ? <FavoriteIconSelected /> : <FavoriteIconNotSelected />}</IconButton>
      {onBluetoothClick && <IconButton size='small' color='secondary' onClick={() => onBluetoothClick()}><BluetoothSearchingIcon fontSize="small" /></IconButton>}
      {displayMore && <IconButton size='small' color='secondary' component={Link} to={`/cluster/${dataProvider.cluster_id}/provider/${dataProvider.id}`}><MoreIcon fontSize="small" /></IconButton>}
    </React.Fragment>);
};

ActionButtons.propTypes = propTypes;
ActionButtons.defaultProps = defaultProps;

export default ActionButtons;
