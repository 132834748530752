import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { HashRouter as Router, Switch } from "react-router-dom";
import { LoginRoute, PrivateRoute } from './components/utils/Route';
import { useWebsocketDispatchAction } from './modules/hooks';
import { theme } from './theme';
import LoginView from './views/LoginView';
import MainRouterView from './views/MainRouterView';

import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';

const App = () => {
  useWebsocketDispatchAction();
  const [curentTheme] = React.useState(theme);
  return (
    <ThemeProvider theme={curentTheme}>
      <Router forceRefresh={false}>
        <Switch>
          <LoginRoute exact path='/login' defaultRedirect='/'>
            <LoginView />
          </LoginRoute>
          <PrivateRoute path='/' defaultRedirect='/login'>
            <MainRouterView />
          </PrivateRoute>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
