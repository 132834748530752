import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Close, OpenInNew } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
  },
  topRightButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, onInNew, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <div className={classes.topRightButton}>
        {onInNew ? (
          <IconButton component={NavLink} to={onInNew}>
            <OpenInNew />
          </IconButton>
        ) : null}

        {onClose ? (
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((/* theme */) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const GenericDialog = ({ children, title, actions, ...props }) => {
  return (
    <MuiDialog {...props}>
      <DialogTitle id="customized-dialog-title" {...props}>
        {title}
      </DialogTitle>
      <form>
        <DialogContent dividers>
          {children}
        </DialogContent>
        {actions ? <DialogActions>
          {actions}
        </DialogActions> : null}
      </form>
    </MuiDialog>
  );
};

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  actions: PropTypes.any,
  onClose: PropTypes.func,
  onInNew: PropTypes.string
};

GenericDialog.propTypes = propTypes;
