import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, useLocation } from "react-router-dom";
import { useAuthRedirect, useIsConnected } from '../../modules/hooks';

const propTypes = {
  children: PropTypes.node,
  defaultRedirect: PropTypes.string.isRequired,
};

export const LoginRoute = ({ children, defaultRedirect, ...rest }) => {
  const isConnected = useIsConnected();
  const [authRedirect] = useAuthRedirect();
  return (
    <Route {...rest} render={() => {
      return !isConnected ? children : <Redirect to={authRedirect || defaultRedirect} />;
    }} />
  );
};

LoginRoute.propTypes = propTypes;

export const PrivateRoute = ({ children, defaultRedirect, ...rest }) => {
  const isConnected = useIsConnected();
  const location = useLocation();
  const [,setAuthRedirect] = useAuthRedirect();
  if (!isConnected)
    setAuthRedirect(`${location.pathname}${location.search}`);
  return (
    <Route {...rest} render={() => { return isConnected ? children : <Redirect to={`${defaultRedirect}`} />; }} />
  );
};

PrivateRoute.propTypes = propTypes;
