import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { dataServiceApi } from '../../../modules/api';

const COLOR = {
  temperature: '#a8323240',
  humidity: '#0000ff40',
  image: 'pink',
  rainfall: 'blue',
  rssi: '#91919140'
};

const processData = (values, dataType, skipModulo) => {
  let i = 0;
  return Object.entries(values).reduce((res, [key, value]) => {
    i++;
    const d = new Date(key);
    switch (dataType) {
      case "rainfall":
        res.push({
          x: new Date(d).setSeconds(d.getSeconds() - value[0][1]),
          y: value[0][0]
        });
        res.push({
          x: d,
          y: value[0][0]
        });
        break;
      default:
        if ((i % skipModulo) === 0) {
          res.push({
            x: d,
            y: value[0]
          });
          break;
        }
    }
    return res;
  }, []);
};

const getDayDiff = (dateFrom, dateTo) => (dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24);

const generateCacheKey = (id, date) => `${id}_${date.toDateString()}`;

// eslint-disable-next-line
const fetchData = async (dataServices, dateFrom, dateTo, onProgress = () => { }, dataSetCache = {}) => {
  const dataSet = {};
  const dayNumber = getDayDiff(dateFrom, dateTo) + 1;
  const currentDate = new Date(dateFrom);
  const promiseSet = [];
  let progress = 0;
  for (let i = 0; i < dayNumber; i++) {
    const promises = dataServices.map(ds => {
      dataSet[ds.id] = dataSet[ds.id] || [];
      const cacheKey = generateCacheKey(ds.id, currentDate);
      if (dataSetCache[cacheKey]) {
        dataSet[ds.id][i] = dataSetCache[cacheKey];
        return Promise.resolve(true);
      }
      return dataServiceApi
        .fetchDataByDate(ds.id, currentDate)
        .then((fetchRes) => {
          dataSet[ds.id][i] = processData(fetchRes.values, ds.type, dayNumber);
          dataSetCache[cacheKey] = dataSet[ds.id][i];
        })
        .catch(() => {
          dataSetCache[cacheKey] = [];
        })
        .finally(() => {
          onProgress(++progress * 100 / (dayNumber * dataServices.length));
        });
    }
    );

    // Push promises in promiseSet
    promiseSet.push(...promises);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Wait for promises
  await Promise.all(promiseSet);
  onProgress(100);
  // Flatten data
  Object.keys(dataSet).forEach(key => dataSet[key] = dataSet[key].flat(2));
  const data = {
    labels: [],
    datasets: dataServices.map(dataService => (
      {
        id: dataService.id,
        type: 'line',
        label: `${dataService.type}_${dataService.id}`,
        backgroundColor: COLOR[dataService.type],
        data: dataSet[dataService.id] || [],
        fill: dataService.type !== 'image',
        showLine: dataService.type !== 'image',
        pointBackgroundColor: COLOR[dataService.type],
        hidden: false,
        pointRadius: 0,
      }))
  };
  return data;
};

const hiddenDatasets = (chartData, hiddenDataServiceIDs) => ({
  ...chartData,
  datasets: chartData.datasets.map(dataset => ({ ...dataset, hidden: hiddenDataServiceIDs.includes(dataset.id) }))
});

// eslint-disable-next-line
const DataServiceChart = ({ dataServices, hiddenDataServiceIDs, dateFrom, beginAtZero, dateTo, onProgress }) => {
  const chartRef = React.useRef(null);
  const [data, setData] = React.useState({});
  const fetchCache = React.useRef({});
  React.useEffect(() => {
    onProgress(0);
    fetchData(Object.values(dataServices).flat(), dateFrom, dateTo, onProgress, fetchCache.current)
      .then(data => setData(hiddenDatasets(data, hiddenDataServiceIDs)))
      .finally(() => onProgress(100));
  }, [dataServices, dateFrom, dateTo]);

  React.useEffect(() => {
    if (!data.datasets) return;
    setData(hiddenDatasets(data, hiddenDataServiceIDs));
  }, [hiddenDataServiceIDs]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        type: 'time',
        min: dateFrom.getTime(),
        max: dateTo.getTime() + 24 * 3600000,
        time: {
          unit: getDayDiff(dateFrom, dateTo) > 0 ? getDayDiff(dateFrom, dateTo) > 7 ? 'week' : 'day' : 'hour',
        }
      },
      y: {
        beginAtZero: beginAtZero,
      }
    }
  };
  return (<Line ref={chartRef} options={options} data={data} />);
};

DataServiceChart.propTypes = {
  dataServices: PropTypes.array.isRequired,
  hiddenDataServiceIDs: PropTypes.array,
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired,
  onProgress: PropTypes.func,
  beginAtZero: PropTypes.bool
};

DataServiceChart.defaultProps = {
  hiddenDataServiceIDs: [],
  beginAtZero: true
};

export default DataServiceChart;
