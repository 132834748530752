import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.secondaryAppBarHeight
  },
  title: {
    // margin: 'auto'
    paddingRight: theme.spacing(3),
    minWidth: "180px"
  },
  grow: {
    flexGrow: 1,
  },
}));

const propTypes = {
  leftAction: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number
  ]),
  rightAction: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number
  ]),
  title: PropTypes.string
};

const AbstractAppbar = ({ title, leftAction, rightAction }) => {
  const classes = useStyles();

  return (
    <AppBar color="default" position="sticky" className={classes.root}>
      <Toolbar className={classes.root}>
        {
          title ? (
            <Typography align="center" variant="h6" color="primary" className={classes.title} noWrap>
              <strong>{title}</strong>
            </Typography>) : null
        }
        {leftAction}
        <div className={classes.grow} />
        {rightAction}
      </Toolbar>
    </AppBar>);
};

AbstractAppbar.propTypes = propTypes;

export default AbstractAppbar;
