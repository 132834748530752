import { Breadcrumbs, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import React from 'react';
import { useParams } from "react-router-dom";
import AbstractAppbar from '../../../components/appbar/AbstractAppbar';
import { useDataProvider, useDataServiceByDataProvider, useFetchDataService } from '../../../modules/hooks';
import AbstractMainView from '../AbstractMainView';

const dataProviderIdKey = 'dataProviderId';
const useDataProviderIdParams = () => parseInt(useParams()[dataProviderIdKey]);
const dataServiceIdKey = 'dataServiceId';
const useDataServiceIdParams = () => parseInt(useParams()[dataServiceIdKey]);

const ServiceDetailView = () => {
  const dataProviderId = useDataProviderIdParams();
  const dataServiceId = useDataServiceIdParams();
  const fetchDataService = useFetchDataService();

  React.useEffect(() => {
    fetchDataService(dataProviderId);
  }, [dataProviderId]);

  // const ids = React.useMemo(() => dataProviderId.split(',').map(v => parseInt(v)), [dataProviderId]);
  const dataProvider = useDataProvider(dataProviderId);
  const dataService = useDataServiceByDataProvider(dataProviderId).find(ds => ds.id === dataServiceId);
  if (!dataProvider)
    return 'wait for loading';
  if (!dataService)
    return 'service id not found';
  return (
    <AbstractMainView
      appBar={
        <AbstractAppbar
          leftAction={
            <Typography variant="h6" color="primary" noWrap>
              <Breadcrumbs>
                <Link color="inherit" href={`#/cluster/${dataProvider.cluster_id}/providers`}>
                  Cluster
                </Link>
                <Link color="inherit" href={`#/cluster/${dataProvider.cluster_id}/provider/${dataProvider.id}`}>
                  Equipment
                </Link>
                <Typography color="textPrimary">{`${dataService.type}-${dataService.channel}`}</Typography>
              </Breadcrumbs>
            </Typography>
          }
        />}
      app={
        <Container>

        </Container>
      }
    />
  );
};

export default ServiceDetailView;
