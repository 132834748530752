import { doGet } from './base';

export default {
  fetchAll() {
    return doGet('/api/client/autonomous-device/');
  },
  fetch(autonomousDeviceId) {
    return doGet(`/api/client/autonomous-device/${autonomousDeviceId}/`);
  }
};
