
import { Card, Container, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import UserDataForm from '../../components/account/UserDataForm';
import AdditionalEmailList from '../../components/account/AdditionalEmailList';
import { useSetNavTitle } from '../../modules/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  card: {
    padding: theme.spacing(2)
  }
}));

const AccountView = () => {
  const classes = useStyles();
  useSetNavTitle('Account');

  return (
    < Container className={classes.root}>
      <Grid
        container
        spacing={2}>
        <Grid item md={12}>
          <UserDataForm />
        </Grid>
        <Grid item md={12}>
          <Card className={classes.card}>
            <AdditionalEmailList />
          </Card>
        </Grid>
      </Grid>
    </Container >
  );
};
export default AccountView;
