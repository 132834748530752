import { Box, Button, ButtonGroup, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon, Notifications as NotificationsIcon, NotificationsOff as NotificationsOffIcon } from '@material-ui/icons/';
import PropTypes from 'prop-types';
import React from 'react';
import { Add } from '@material-ui/icons';

const propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  info: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onNotifications: PropTypes.func,
  notificationStatus: PropTypes.bool,
  children: PropTypes.node
};

const defaultProps = {
  title: '',
  subtitle: '',
  info: '',
  notificationStatus: false,
  onEdit: null,
  onDelete: null,
  icon: null
};

export const GenericListItem = ({ title, subtitle, onEdit, onDelete, onNotifications, info, notificationStatus, ...props }) => {
  if (info)
    console.log('foo');
  const NotificationIconStatus = notificationStatus ? <NotificationsIcon /> : <NotificationsOffIcon />;
  return (
    <ListItem divider disableGutters {...props}>
      {/* <ListItemIcon>

      </ListItemIcon> */}
      <ListItemText primary={title} secondary={subtitle}>
      </ListItemText>
      <ListItemSecondaryAction>
        <ButtonGroup fullWidth size="small">
          {onEdit && (<Button onClick={onEdit} color="secondary" size='small'> <EditIcon /> </Button>)}
          {onNotifications && (<Button onClick={onNotifications} color="secondary" size='small'> {NotificationIconStatus} </Button>)}
          {onDelete && (<Button onClick={onDelete} color="secondary" size='small'> <DeleteIcon /> </Button>)}
        </ButtonGroup>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

GenericListItem.propTypes = propTypes;
GenericListItem.defaultProps = defaultProps;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  addButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0)
  },
  emptyMessage: {
    paddingTop: theme.spacing(4),
    bottomTop: theme.spacing(4)
  }
}));

export const GenericList = ({ title, onAdd, emptyMessage, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant='h5' color='primary'>
        {title}
      </Typography>
      {onAdd ?
        <IconButton onClick={onAdd} className={classes.addButton} size='small'>
          <Add />
        </IconButton>
        : null}
      <Divider />
      {
        props.children && props.children.length === 0 ?
          (<Typography className={classes.emptyMessage} fullWidth align='center' color='textSecondary'>{emptyMessage}</Typography>)
          : null
      }
      <List {...props}>
      </List>
    </Box >);
};

GenericList.propTypes = {
  title: PropTypes.string,
  onAdd: PropTypes.func,
  emptyMessage: PropTypes.string,
  children: PropTypes.node
};

GenericList.defaultProps = {
  items: [],
  emptyMessage: 'Empty list item'
};
