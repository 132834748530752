import React from 'react';
import AbstractClusterView from './AbstractClusterView';

const MapView = () => {
  return (
    <AbstractClusterView
      app={
        "Map comming soon"
      }
      title='Map'
      rightAction={""}
    />);
};
export default MapView;
