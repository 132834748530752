import { fetchDPErrors, fetchGateway } from '../moduleDataProvider/actions';
import { dataProviderMapSelector } from '../selectors';

const PREFIX = 'WS';
const wsActionTypeFormater = (action, type) => `${PREFIX}_${String(action)}_${String(type)}`.toUpperCase();

export const UPDATE_BLUETOOTH_SCANNED_DEVICES = wsActionTypeFormater('UPDATE', 'BLUETOOTH-SCANNED-DEVICES');
export const UPDATE_BLUETOOTH_DEVICE_PAIR = wsActionTypeFormater('UPDATE', 'BLUETOOTH-DEVICE-PAIR');
export const DESTROY_BLUETOOTH_DEVICE_PAIR = wsActionTypeFormater('DESTROY', 'BLUETOOTH-DEVICE-PAIR');
export const CREATE_ERRORS = wsActionTypeFormater('CREATE', 'ERRORS');
export const UPDATE_STATUS = wsActionTypeFormater('UPDATE', 'STATUS');

const actionMessage = (type, message, clusterId) => {
  return { type, message, clusterId };
};

export const receiveWebSocketMessage = (message) => {
  return (dispatch, getState) => {
    const dataProvider = dataProviderMapSelector(getState())[message.id];
    if (!dataProvider)
      throw 'not found dataProvider';
    const type = wsActionTypeFormater(message.action, message.type);
    switch (type) {
      case CREATE_ERRORS:
        dispatch(fetchDPErrors(message.id));
        break;
      case UPDATE_STATUS:
        dispatch(fetchGateway());
        break;
      default:
        break;
    }

    return dispatch(actionMessage(type, message, dataProvider.cluster_id));
  };
};
