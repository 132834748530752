import axios from 'axios';
import CONFIG from '../../config';
import { BaseApiException, CancelApiException, NotFoundApiException, TimeoutApiException } from './exceptions';
export const CancelToken = axios.CancelToken;

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export const setCommonAuth = (token) => {
  axios.defaults.headers.common['Authorization'] = 'token ' + token;
};

export const removeCommonAuth = () => {
  axios.defaults.headers.common['Authorization'] = '';
};

axios.interceptors.response.use(
  response => response.data,
  thrown => {
    if (axios.isCancel(thrown))
      throw new CancelApiException();
    else if (thrown.code === 'ECONNABORTED')
      throw new TimeoutApiException(thrown.config.url, thrown.config.timeout);
    else if (thrown.isAxiosError && thrown.response) {
      if (thrown.response.status === 408)
        throw new TimeoutApiException(thrown.config.url);
      else if (thrown.response.status === 404)
        throw new NotFoundApiException(thrown.response.status);
      let status = thrown.response.status;
      let message = thrown.response.data ? thrown.response.data : thrown.toString();
      throw new BaseApiException(message, status);
    }
    else
      throw thrown;
  }
);

// eslint-disable-next-line
const doRequest = (type, url, { body = null, parameters = {}, contentType = undefined, ...rest }, token) => {
  return axios({
    method: ['POST', 'GET', 'PATCH', 'DELETE'].includes(type.toUpperCase()) ? type : 'GET',
    url: CONFIG.VUE_APP_BRIDGE_URL + url,
    headers: { 'Content-Type': contentType ? contentType : 'application/json', 'Accept-Language': 'fr-fr' },
    params: { ...parameters },
    data: body,
    cancelToken: token,
    ...rest
  });
};

export const doGet = (url, { parameters = {}, contentType = undefined, ...rest } = {}, token = undefined) => {
  return doRequest('GET', url, { parameters, contentType, ...rest }, token, contentType);
};
export const doPost = (url, { body = {}, parameters = {}, contentType = undefined, ...rest } = {}, token = undefined) => {
  return doRequest('POST', url, { body, parameters, contentType, ...rest }, token, contentType);
};
export const doPatch = (url, { body = {}, parameters = {}, contentType = undefined, ...rest } = {}, token = undefined) => {
  return doRequest('PATCH', url, { body, parameters, contentType, ...rest }, token, contentType);
};
export const doDelete = (url, { parameters = {}, contentType = undefined, ...rest } = {}, token = undefined) => {
  return doRequest('DELETE', url, { parameters, contentType, ...rest }, token);
};

const imageUrlObjects = {};

export const doGetImage = async (url, clearCache = false) => {
  if (imageUrlObjects[url] && !clearCache)
    return await Promise.resolve(imageUrlObjects[url]);
  try {
    const image = await doGet(url, { responseType: 'blob' });
    imageUrlObjects[url] = window.URL.createObjectURL(image);
  } catch {
    imageUrlObjects[url] = null;
  }
  return imageUrlObjects[url];
};
