import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';

const Notifier = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notificationsSuccess = useSelector(state => state.notifications.notificationsSuccess);
  const notificationsError = useSelector(state => state.notifications.notificationsError);
  React.useEffect(() => {
    if (notificationsSuccess.length > 0) {
      enqueueSnackbar(notificationsSuccess[0].message, { key: Math.random(), variant: "success" });
    }
  }, [notificationsSuccess]);

  React.useEffect(() => {
    if (notificationsError.length > 0) {
      enqueueSnackbar(notificationsError[0].message, { key: Math.random(), variant: "error" });
    }
  }, [notificationsError]);

  return null;
};

export default Notifier;
