import { doGet, doPatch } from './base';

export default {
  fetchDataServices(dataProviderId) {
    return doGet(`/api/client/data-provider/${dataProviderId}/data-service/`);
  },
  fetchDataProviderErrors(dataProviderId) {
    return doGet(`/api/client/data-provider/${dataProviderId}/errors/`);
  },
  patchDataProvider(dataProviderId, body) {
    return doPatch(`/api/client/data-provider/${dataProviderId}/`, { body });
  },
  patchDataProviderLabel(dataProviderId, label) {
    return doPatch(`/api/client/data-provider/${dataProviderId}/label/`, { body: { name: label } });
  }
};
