import React from 'react';
import { useSetNavTitle } from '../../modules/hooks';

const SettingsView = () => {
  useSetNavTitle('Settings');
  return (
    <React.Fragment>
      settings
    </React.Fragment>);
};
export default SettingsView;
