import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React from 'react';

const TODAY = new Date(new Date().toISOString().split('T')[0]);

const propTypes = {
  disable: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

const defaultProps = {
  disable: false,
  onChange: () => {}
};

export const RangeDatePicker = ({disable, onChange}) => {
  const [{from, to}, setDates] = React.useState({from: TODAY, to: TODAY});
  const handledateFrom = (date) => {
    date = date || new Date();
    const newDate = new Date(date.toISOString().split('T')[0]);
    setDates({ from: newDate, to: newDate > to ? newDate : to });
  };
  const handledateTo = (date) => {
    date = date || new Date();
    const newDate = new Date(date.toISOString().split('T')[0]);
    setDates({ from: newDate < from ? newDate : from, to: newDate });
  };
  React.useEffect(() => onChange({from, to}),[from, to]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        disabled={disable}
        autoOk
        label="Start"
        clearable
        disableFuture
        value={from}
        onChange={handledateFrom}
      />
      <DatePicker
        disabled={disable}
        autoOk
        label="End"
        clearable
        disableFuture
        value={to}
        onChange={handledateTo}
      />
    </MuiPickersUtilsProvider>);
};

RangeDatePicker.propTypes = propTypes;
RangeDatePicker.defaultProps = defaultProps;
