import { HumidityIcon, ImageIcon, PIRIcon, SoundIcon, SwitchIcon, TemperatureIcon, GpsIcon, UnknownIcon } from '../components/icons/DataServiceIcons';
import { formatDateTime, formatFriendlyDate } from '../libs/dateHelper';


/**
 * This function is use to convert value format {[Date]: [value,lon,lat]} => {date, value, position: {lon, lat}}
 * @param {Object} dataValues - The backend data value format with shape
 * @returns {Object} Reformated value with shape
 */
export const reformatDataValue = (dataServiceId, dataValue) => {
  const entrie = Object.entries(dataValue)[0];
  const date = entrie[0];
  const entrieValues = entrie[1];
  return {
    date,
    value: entrieValues[0],
    position: {
      lon: entrieValues[1],
      lat: entrieValues[2]
    }
  };
};

/**
 *
 * @param {*} type
 * @param {*} value
 */
export const dataRender = (type, value) => {
  let renderIcon = UnknownIcon;
  let formatedData = 'ND';

  if (value !== undefined && value !== null) {
    switch (type) {
      case "temperature":
        renderIcon = TemperatureIcon;
        formatedData = `${Number((value).toFixed(1))}°C`;
        break;
      case "humidity":
        renderIcon = HumidityIcon;
        formatedData = `${Number((value).toFixed(1))}%`;
        break;
      case "buzz":
        renderIcon = SoundIcon;
        formatedData = `${value[0] ? 'allumé' : 'etteint'}${value[0] && value[1] ? ` pendant ${value[1]}s` : ''}`;
        break;
      case "digital-output":
        renderIcon = SwitchIcon;
        formatedData = `${value[0] ? 'ouvert' : 'fermé'}${value[0] && value[1] ? ` pendant ${value[1]}s` : ''}`;
        break;
      case "image":
        renderIcon = ImageIcon;
        formatedData = value ? `${value[0][0]} picture` : value;
        break;
      case "pir":
        renderIcon = PIRIcon;
        formatedData = `${value}`;
        break;
      case "pressure":
        formatedData = `${Number((value).toFixed(0))}hPa`;
        break;
      case "rainfall":
        formatedData = `${value[0]}mm en ${Number(value[1] / 60).toFixed(0)}min`;
        break;
      case "gps":
        renderIcon = GpsIcon;
        formatedData = `(${Math.trunc(value[0] * 100000) / 100000},${Math.trunc(value[1] * 100000) / 100000})`;
        break;
      case "weight":
        formatedData = `${Number((value).toFixed(1))}Kg`;
        break;
      default:
        formatedData = `${value} `;
        break;
    }
  }
  // Ensure string format
  formatedData = `${formatedData} `;
  return {
    renderIcon,
    formatedData
  };
};

/**
 *
 * @param {*} type
 * @param {*} data
 * @param {*} local
 */
export const dataRenderFormater = (type, data, local) => {
  const date = data ? new Date(data.date) : null;
  const { renderIcon, formatedData } = dataRender(type, data ? data.value : null);
  return {
    formatedData,
    renderIcon,
    date,
    dateString: date ? formatFriendlyDate(date, local) : '',
    friendlyDateString: date ? formatDateTime(date, local) : ''
  };
};
