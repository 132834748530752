import * as Actions from '../actions';

const initState = () => {
  return {
    navTitle: ''
  };
};

export default (state = initState(), action) => {
  switch (action.type) {
    case Actions.NAV_TITLE_CHANGE:
      state.navTitle = action.navTitle;
      break;
    default:
      break;
  }

  return state;
};
