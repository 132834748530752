import { doDelete, doGet, doPatch, doPost } from './base';
import { AuthApiException } from './exceptions';

export default {
  login(username, password) {
    return doPost('/api/client/api-auth/login/', { body: { username, password } })
      .then(res => res.key)
      .catch(error => {
        switch (error.status) {
          case 400:
            throw new AuthApiException('Credential invalid', error.status);
          default:
            throw error;
        }
      });
  },

  fetchUser() {
    return doGet('/api/client/api-auth/user/')
      .catch(error => {
        switch (error.status) {
          case 401:
            throw new AuthApiException('Invalid or expired token', error.status);
          default:
            throw error;
        }
      });
  },
  registerNotificationEmail(email) {
    return doPost('/api/client/user/notification/email/', { body: { email } });
  },
  unregisterNotificationEmail(id) {
    return doDelete(`/api/client/user/notification/email/${id}/`);
  },
  toggleNotificationEmail(id, activated) {
    return doPatch(`/api/client/user/notification/email/${id}/`, { body: { activated } });
  },
  verifyNotificationEmail(email) {
    return doPost('/api/client/user/notification/email/verify/', { body: { email } });
  },
  updateDataProviderLabelFavoris(dataProviderLabels) {
    return doPatch('/api/client/api-auth/user/favoris/data-provider-labels/', { body: { 'data_provider_labels': dataProviderLabels } });
  }
};
