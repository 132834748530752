import { doDelete, doGet, doPatch, doPost } from './base';

const baseUrl = (gatewayId) => { return `/api/client/gateway/${gatewayId}/command/`; };

const apiCommandWrapper = (promise) => {
  return promise.catch(errors => {
    // if (errors.status == 409)
    //   errors.message = i18n.t('api.command.409');
    throw errors;
  });
};

export default {
  // =====
  // Alarm
  // =====
  sendRetrieveAlarm(gatewayId) {
    doGet(baseUrl(gatewayId) + 'alarm/');
  },
  sendSetAlarm(gatewayId, status) {
    doPatch(baseUrl(gatewayId) + 'alarm/', { body: { "alarm": status ? 1 : 0 } });
  },
  // ========
  // Arm Mode
  // ========
  sendRetrieveArmMode(gatewayId) {
    doGet(baseUrl(gatewayId) + 'arm-mode/');
  },
  sendSetArmMode(gatewayId, armMode) {
    return doPatch(`${baseUrl(gatewayId)}arm-mode/`,
      { body: { "arm_mode": armMode, "timeout": 0 } });
  },
  // ============
  // HEALTH CHECK
  // ============
  sendBeginHealthCheck(gatewayId) {
    doPost(baseUrl(gatewayId) + 'health-check/');
  },
  sendRetrieveHealthCheck(gatewayId) {
    doGet(baseUrl(gatewayId) + 'health-check/');
  },
  sendStopHealthCheck(gatewayId) {
    return doDelete(baseUrl(gatewayId) + 'health-check/');
  },

  // =======
  // Picture
  // =======
  sendTakePicture(gatewayId) {
    return doGet(`${baseUrl(gatewayId)}picture/`);
  },
  // ====
  // Ping
  // ====
  sendPing(gatewayId) {
    return doGet(baseUrl(gatewayId) + 'ping/');
  },
  // =========
  // Bluetooth
  // =========
  sendBluetoothScanOn(gatewayId, timeout = 0) {
    return doPost(baseUrl(gatewayId) + 'bluetooth-scan/', {
      body: {
        timeout: timeout
      }
    });
  },
  sendBluetoothDevicePair(gatewayId, mac, connectivity) {
    if (!['broadcast', 'connect'].includes(connectivity))
      throw Error(`wrong value ${connectivity}`);
    return doPost(`${baseUrl(gatewayId)}bluetooth-device-pair/`,
      { body: { "mac": mac, connectivity } });
  },
  sendBluetoothDeviceUnpair(gatewayId, mac) {
    return apiCommandWrapper(doDelete(`${baseUrl(gatewayId)}bluetooth-device-pair/${mac}/`));
  },
  // ======
  // System
  // ======
  retrieveSystem(gatewayId) {
    return doGet(baseUrl(gatewayId) + 'system/');
  },
  // =========
  // Scenarios
  // =========
  getScenario(gatewayId) {
    return doGet(baseUrl(gatewayId) + 'scenario/');
  }
};
