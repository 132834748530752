export class BaseApiException extends Error {
  constructor(message, status) {
    super(message, status);
    this.status = status;
    this.name = "BaseApiException";
    this.isCritical = true;
    this.tag = "unknown";
    this.payload = {};
  }
}

export class CancelApiException extends BaseApiException {
  constructor() {
    super("Fetch cancel", -1);
    this.name = "CancelApiException";
    this.isCritical = false;
    this.tag = "cancel";
  }
}

export class IgnoredApiException extends BaseApiException {
  constructor(message) {
    super(message, -1);
    this.isCritical = false;
    this.name = "IgnoredApiException";
  }
}

export class TimeoutApiException extends BaseApiException {
  constructor(url, timeout) {
    super(`Timeout exception on ${url}, request exceed ${timeout}`, -1);
    this.name = "TimeoutApiException";
    this.tag = "timeout";
    this.payload = { url, timeout };
  }
}

export class AuthApiException extends BaseApiException {
  constructor(message, status) {
    super(message, status);
    this.isCritical = false;
    this.name = "AuthApiException";
  }
}

export class NotFoundApiException extends BaseApiException {
  constructor(status) {
    super("Not found", status);
    this.name = "NotFoundApiException";
    this.isCritical = false;
    this.tag = "cancel";
  }
}
