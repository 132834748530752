import * as Actions from '../actions';

const initState = () => {
  return {
    isFetching: false,
    items: {},
    errors: []
  };
};

export default (state = initState(), action) => {
  switch (action.type) {
    case Actions.REQUEST_API_CLUSTER_METRICS:
      state = {
        ...state,
        isFetching: action.status
      };
      break;
    case Actions.RECEIVE_API_CLUSTER_METRICS:
      state = {
        ...state,
        items: {
          ...state.items,
          [action.clusterId]: action.clusterMetrics
        }
      };
      break;
    case Actions.RECEIVE_API_METRICS_ERROR:
      state = {
        ...state,
        errors: [action.error, ...state.errors]
      };
  }
  return state;
};
