import React from 'react';
import DataProviderList from '../../../components/clusterDetail/DataProviderList';
import AbstractClusterView from './AbstractClusterView';
import { useTranslation } from 'react-i18next';

const ProviderListView = () => {
  const { t } = useTranslation();

  return (
    <AbstractClusterView
      paddingTop={ 0 }
      app={
        <DataProviderList />
      }
      title={ t('providers.title') }
      rightAction={ "" }
    />
  );
};
export default ProviderListView;
