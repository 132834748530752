export const ENQUEUE_SUCCESS_SNACKBAR = 'ENQUEUE_SUCCESS_SNACKBAR';
export const ENQUEUE_ERROR_SNACKBAR = 'ENQUEUE_ERROR_SNACKBAR';


export const enqueueSuccessNotification = (message) => {
  return {
    type: ENQUEUE_SUCCESS_SNACKBAR,
    notification: {
      message
    },
  };
};

export const enqueueErrorNotification = (message) => {
  return {
    type: ENQUEUE_ERROR_SNACKBAR,
    notification: {
      message
    },
  };
};
