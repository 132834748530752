import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, Fab, Grow, IconButton, InputBase, List, ListItem, makeStyles } from '@material-ui/core';
import { Close as CloseIcon, SearchSharp as SearchIcon } from '@material-ui/icons';
import { debounce } from "debounce";
import { DataProviderMinimalInfo } from '../../shared/DataProviderMinimalInfo';

const useStyles = makeStyles((theme) => ({
  floatingButton: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
  },
  root: {
    zIndex: 10,
    position: 'absolute',
    top: theme.spacing(3),
    bottom: theme.spacing(3),
    left: theme.spacing(3),
    right: theme.spacing(3),
    background: '#ffffffeb'
  },
  form: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  listWrapper: {
    position: 'absolute',
    top: 48,
    bottom: 0,
    width: '100%'
  },
  list: {
    overflow: 'auto',
    height: '100%'
  }
}));

const useFilteredDataProviders = (dataProviders, filterString) => {
  return React.useMemo(() => {
    if (filterString === null)
      return dataProviders;
    return dataProviders.filter(dataProvider => {
      const matchString = `${dataProvider.label.name} ${dataProvider.identifier}`;
      return matchString.toLowerCase().match(filterString.toLowerCase());
    });
  }, [dataProviders, filterString]);
};

const DataProviderMapModalSearch = ({ dataProviders, onDataProviderClicked, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [filterString, setFilterString] = React.useState(null);
  const filteredDataProviders = useFilteredDataProviders(dataProviders, filterString);
  const onSearch = debounce((event) => setFilterString(event.target.value), 300);

  return (
    <React.Fragment>
      <Fab
        className={classes.floatingButton}
        size='small'
        color="secondary"
        onClick={() => setOpen((prev) => !prev)}
        {...props}>
        <SearchIcon />
      </Fab>
      <Grow in={open}>
        <Card className={classes.root}>
          <form className={classes.form}>
            <SearchIcon />
            <InputBase
              className={classes.input}
              placeholder="Search Material"
              onChange={onSearch}
            />
            <IconButton
              className={classes.iconButton}
              onClick={() => setOpen((prev) => !prev)}>
              <CloseIcon />
            </IconButton>
          </form>
          <Divider />
          <div className={classes.listWrapper}>
            <List dense className={classes.list}>
              {
                filteredDataProviders.map(dp =>
                  <ListItem key={dp.id} button onClick={() => setOpen(onDataProviderClicked(dp))} divider>
                    <DataProviderMinimalInfo dataProvider={dp}></DataProviderMinimalInfo>
                  </ListItem>)
              }
            </List>
          </div>
        </Card>
      </Grow>
    </React.Fragment>
  );
};


DataProviderMapModalSearch.propTypes = {
  onDataProviderClicked: PropTypes.func,
  dataProviders: PropTypes.array
};
DataProviderMapModalSearch.defaultProps = {
  onDataProviderClicked: () => true,
  dataProviders: []
};

export default DataProviderMapModalSearch;
