import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginBackground from '../assets/img/login-backroung.jpg';
import LoginForm from '../components/auth/LoginForm';
import { signIn } from '../modules/actions';

const useStyles = makeStyles((theme) => ({
  loginPage: {
    backgroundImage: `url(${LoginBackground})`,
    height: '100vh',
    backgroundSize: 'cover'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  loginContainer: {
    backgroundColor: '#ffffffe0',
    height: '100vh',
    padding: theme.spacing(5)
  },
  formContainer: {
    top: '50%',
    position: 'relative',
    transform: 'translateY(-50%)',
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    "& .loginLogo": {
      width: '100%',
      marginBottom: theme.spacing(10)
    },
    '& .linkContainer': {
      marginTop: theme.spacing(10)
    }
  }
}));

const LoginView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isFetchingUser = useSelector(state => state.auth.isFetchingUser);
  // Display nothing when user is fetching
  if (isFetchingUser)
    return (null);
  return (
    <div className={classes.loginPage}>
      <Grid container spacing={0}>
        <Grid className={classes.loginContainer} item xs={12} md={4}>
          <LoginForm onLogin={(login, password) => dispatch(signIn(login, password))}/>
        </Grid>
      </Grid>
    </div>);
};

export default LoginView;
