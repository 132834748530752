import React from 'react';
import { useDispatch } from 'react-redux';
import { receiveWebSocketMessage } from './actions';

export const WebSocketContext = React.createContext(null);

export const WebSocketDataContext = React.createContext(null);

export const useWebsocketMessage = (callback, filter=null) => {
  const webSocketHandler = React.useContext(WebSocketContext);
  React.useEffect(() => {
    const wsHandler = (...args) => {
      if (!filter || filter(...args))
        callback(...args);
    };
    if (webSocketHandler) { webSocketHandler.addOnMessageListener(wsHandler); }
    return () => { if (webSocketHandler) { webSocketHandler.removeOnMessageListener(wsHandler); }
    };
  },
  [webSocketHandler, callback, filter]);
};

export const useWebsocketDispatchAction = () => {
  const dispatch = useDispatch();
  useWebsocketMessage((message) => dispatch(receiveWebSocketMessage(message)));
};

export const useWebsocketError = (callback, dpID, errorType='[A-Z]') => {
  const filter = React.useRef((message) => (message.type === 'errors'));
  const cb = React.useMemo(() => function(message) {
    const regex = new RegExp(`(^${errorType}{0,})([0-9]{0,})`);
    if (message.payload.error_id.match(regex) && message.id === dpID)
      callback(message);
  }, [callback, errorType]);
  useWebsocketMessage(cb, filter.current);
};

export const useWebsocketErrorBLE = (callback) => {
  useWebsocketError(callback, 'BLE');
};

export const useWebsocketErrorGTW = (callback) => {
  useWebsocketError(callback, 'GTW');
};

export const useWebsocketErrorDEV = (callback) => {
  useWebsocketError(callback, 'DEV');
};
