import { combineReducers } from 'redux';
import authReducer from './moduleAuth/reducer';
import clustersReducer from './moduleCluster/reducer';
import clusterMetricsReducer from './moduleClusterMetrics/reducer';
import clusterEventConfigurationsReducer from './moduleClusterEventConfigurations/reducer';
import dataReducer from './moduleData/reducer';
import dataProvidersReducer from './moduleDataProvider/reducer';
import dataServicesReducer from './moduleDataService/reducer';
import navReducer from './moduleNav/reducer';
import appNotificationReducer from './moduleAppNotification/reducer';

export const rootReducer = () => {
  return combineReducers({
    auth: authReducer,
    clusters: clustersReducer,
    clusterMetrics: clusterMetricsReducer,
    clusterEventConfigurations: clusterEventConfigurationsReducer,
    dataProviders: dataProvidersReducer,
    dataServices: dataServicesReducer,
    nav: navReducer,
    data: dataReducer,
    notifications: appNotificationReducer
  });
};

/**
 * generateSubscriberHandler
 * @param {*} store redux store
 * @param {*} selector redux state selector (can use reselect)
 * @param {*} cb listener (newValue) => {}
 */
export const generateSubscriberHandler = (store, selector, cb) => {
  let currentValue = undefined;
  return () => {
    const previousValue = currentValue;
    currentValue = selector(store.getState());
    if (previousValue !== currentValue)
      cb(currentValue);
  };
};
