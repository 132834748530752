import * as Actions from './actions';

const initState = () => {
  return {
    isFetching: false,
    lastValueByDataServiceId: {},
    error: null
  };
};

export default (state = initState(), action) => {
  switch (action.type) {
    case Actions.RECEIVE_LAST_VALUE:
      state = {
        ...state,
        lastValueByDataServiceId: {
          ...state.lastValueByDataServiceId,
          [action.dataServiceId]: action.lastValue
        }
      };
      break;
    case Actions.DATA_API_ERROR:
      state = {
        ...state,
        error: action.error
      };
      break;
    default:
      break;
  }

  return state;
};
