import { Container, ImageList, ImageListItem, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ClustersAppbar from '../../components/appbar/ClustersAppbar';
import ClusterCard from '../../components/clusterList/ClusterCard';
import { useAuthUser, useClusterList, useSetNavTitle } from '../../modules/hooks';
import AbstractMain from './AbstractMainView';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-around',
    overflow: 'hidden',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1)
  }
}));

const ALL = "ALL", OWNED = "OWNED", FAVORITES = "FAVORITES";

const useFilteredCluster = (filter, search) => {
  const clusters = useClusterList();
  const authUser = useAuthUser();
  return React.useMemo(() => {
    let res = [];
    switch (filter) {
      case ALL:
        res = clusters;
        break;
      case OWNED:
        res = clusters.filter(cluster => cluster.owner === authUser.id);
        break;
      case FAVORITES:
        throw "FAVORITES filter not yet implemented";
      default:
        break;
    }
    return res.filter(cluster => {
      const matchString = `${cluster.name} ${cluster.description} ${cluster.cluster_memberships.map(m => m.user.first_name + m.user.last_name).join(" ")}`;
      return matchString.toLowerCase().match(search.toLowerCase());
    });
  }, [clusters, filter, search, authUser]);
};

const ClustersView = () => {
  const { t } = useTranslation();
  useSetNavTitle(t('clusters.list.title'));
  const classes = useStyles();
  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [filter, /* setFilter */] = React.useState(ALL);
  const [search, setSearch] = React.useState("");
  const clusters = useFilteredCluster(filter, search);
  const handleSearchChange = event => setSearch(event.target.value);
  return (
    <AbstractMain
      paddingTop={ 0 }
      appBar={
        <ClustersAppbar onSearchChange={ handleSearchChange } />
      }
      app={
        <Container >
          <div className={ classes.root }>
            <ImageList gap={ 12 } rowHeight="auto" className={ classes.gridList } cols={ matches && clusters.length >= 3 ? 3 : 1 }>
              { clusters.map(cluster =>
                <ImageListItem key={ cluster.id }>
                  <ClusterCard cluster={ cluster } openable />
                </ImageListItem>) }
            </ImageList>
          </div>
        </Container >
      }
    >
    </AbstractMain >
  );
};
export default ClustersView;
