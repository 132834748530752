import { clusterApi } from '../api';
import { BaseApiException } from '../api/exceptions';
import { enqueueErrorNotification } from '../moduleAppNotification/actions';

const processError = (dispatch, error) => {
  if (error instanceof BaseApiException
    && error.status > 300
    && error.status < 500)
    return error;
  dispatch(enqueueErrorNotification(error.message));
  throw error;
};

export const RECEIVE_API_METRICS_ERROR = 'RECEIVE_API_METRICS_ERROR';
const clusterApiError = (error) => {
  return { type: RECEIVE_API_METRICS_ERROR, error };
};

// ===============
// Cluster Metrics
// ===============

export const REQUEST_API_CLUSTER_METRICS = 'REQUEST_API_CLUSTER_METRICS';
const requestClusterMetrics = (status) => {
  return { type: REQUEST_API_CLUSTER_METRICS, status };
};

export const RECEIVE_API_CLUSTER_METRICS = 'RECEIVE_API_CLUSTER_METRICS';
const receiveClusterMetrics = (clusterMetrics, clusterId) => {
  return { type: RECEIVE_API_CLUSTER_METRICS, clusterMetrics, clusterId };
};

export const fetchClusterMetrics = (clusterId) => {
  return (dispatch) => {
    dispatch(requestClusterMetrics(true));
    return clusterApi.fetchClusterMetrics(clusterId)
      .then(clusterMetrics => dispatch(receiveClusterMetrics(clusterMetrics, clusterId)))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))))
      .finally(() => dispatch(requestClusterMetrics(false)));
  };
};
