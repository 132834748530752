import { doGet, doPatch, doGetImage } from './base';

// const formatIsoDate = date => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
const formatIsoDate = date => date.toISOString().split('T')[0];

export default {
  sendCommandPicture(dataServiceId, timeout = 0) {
    return doPatch(`/api/client/data-service/${dataServiceId}/command/image/`, {
      body: { timeout }
    });
  },
  sendCommandBuzz(dataServiceId, value, timeout = 10) {
    return doPatch(`/api/client/data-service/${dataServiceId}/command/buzz/`, {
      body: { timeout, value }
    });
  },
  sendCommandDigitalOutput(dataServiceId, value = 0, timeout = 10) {
    return doPatch(`/api/client/data-service/${dataServiceId}/command/digital-output/`, {
      body: { timeout, value }
    });
  },
  fetchDataByDate(dataServiceId, date = new Date()) {
    return doGet(`/api/client/data/${dataServiceId}/?date=${formatIsoDate(date).substr(0, 10)}`);
  },
  fetchDataLastValue(dataServiceId) {
    return doGet(`/api/client/data/${dataServiceId}/last-value/`);
  },
  fetchDataImage(imageName) {
    return doGetImage(`/api/client/data/image/${imageName}/`);
  },
};
