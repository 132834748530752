import { clusterApi } from '../api';
import { BaseApiException } from '../api/exceptions';
import { enqueueErrorNotification } from '../moduleAppNotification/actions';

const processError = (dispatch, error) => {
  if (error instanceof BaseApiException
    && error.status > 300
    && error.status < 500)
    return error;
  dispatch(enqueueErrorNotification(error.message));
  throw error;
};

export const CLUSTER_API_ERROR = 'CLUSTER_API_ERROR';
const clusterApiError = (error) => {
  return { type: CLUSTER_API_ERROR, error };
};

// =======
// Cluster
// =======

export const REQUEST_CLUSTERS = 'REQUEST_CLUSTERS';
const requestClusters = () => {
  return { type: REQUEST_CLUSTERS };
};

export const RECEIVE_CLUSTERS = 'RECEIVE_CLUSTERS';
const receiveClusters = (clusters) => {
  return { type: RECEIVE_CLUSTERS, clusters };
};

export const fetchCluster = () => {
  return (dispatch) => {
    dispatch(requestClusters());
    return clusterApi.fetchAll()
      .then(clusters => dispatch(receiveClusters(clusters)))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))));
  };
};

export const UPSERT_CLUSTER_BEGIN = 'UPSERT_CLUSTER_BEGIN';
const upsertClusterBegin = () => {
  return { type: UPSERT_CLUSTER_BEGIN };
};

export const UPSERT_CLUSTER_RECEIVED = 'UPSERT_CLUSTER_RECEIVED';
const upsertClusterReceived = (cluster) => {
  return { type: UPSERT_CLUSTER_RECEIVED, cluster };
};

export const upsertCluster = (cluster) => {
  return (dispatch) => {
    const f = cluster.id ? clusterApi.updateCluster : clusterApi.createCluster;
    dispatch(upsertClusterBegin());
    return f(cluster)
      .then(cluster => dispatch(upsertClusterReceived(cluster)))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))));
  };
};

// =============
// Cluster Image
// =============

export const updateImageCluster = (cluster, imageFile) => {
  return (dispatch) => {
    dispatch(upsertClusterBegin());
    return clusterApi.updateImageCluster(cluster, imageFile)
      .then(() => dispatch(fetchCluster()))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))));
  };
};

// ======================
// Cluster Event Document
// ======================

export const REQUEST_DP_EVENTS_DOCUMENT = 'REQUEST_DP_EVENTS_DOCUMENT';
const requestDpEventsDocuments = (status) => ({ type: REQUEST_DP_EVENTS_DOCUMENT, status });

export const RECEIVE_DP_EVENTS_DOCUMENT = 'RECEIVE_DP_EVENTS_DOCUMENT';
const receiveDpEventsDocuments = (eventsDocuments, clusterId) => ({ type: RECEIVE_DP_EVENTS_DOCUMENT, eventsDocuments, clusterId });

export const fetchClusterDpEventsDocuments = (clusterId) => {
  return (dispatch) => {
    dispatch(requestDpEventsDocuments(true));
    return clusterApi.fetchEventsDocument(clusterId)
      .then(eventsDocuments => dispatch(receiveDpEventsDocuments(eventsDocuments, clusterId)))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))))
      .finally(() => dispatch(requestDpEventsDocuments(false)));
  };
};
