import { reformatDataValue } from '../../libs/dataValueHelper';
import { dataServiceApi } from '../api';
import { BaseApiException, NotFoundApiException } from '../api/exceptions';
import { lastValueMapByDataServiceIdSelector } from './selector';


export const DATA_API_ERROR = 'DATA_API_ERROR';
const apiErrorData = (error) => {
  return { type: DATA_API_ERROR, error };
};

// ======================================
// Fetch DataProvider Services Last Value
// ======================================

export const RECEIVE_LAST_VALUE = 'RECEIVE_LAST_VALUE';
export const receiveLastValue = (dataServiceId, lastValue) => {
  return { type: RECEIVE_LAST_VALUE, dataServiceId, lastValue };
};

export const fetchDataServiceLastValue = (dataServiceId) => {
  return (dispatch, getState) => {
    return dataServiceApi.fetchDataLastValue(dataServiceId)
      .then(res => {
        if (!res)
          return;

        const dataLastValueMapByDataServiceId = lastValueMapByDataServiceIdSelector(getState());
        const previousDataLastValue = dataLastValueMapByDataServiceId[dataServiceId];
        const dataLastValue = reformatDataValue(dataServiceId, res);
        // Update only if necessary
        if (!previousDataLastValue || previousDataLastValue.date !== dataLastValue.date)
          dispatch(receiveLastValue(dataServiceId, dataLastValue));
      })
      .catch(error => {
        if (error instanceof NotFoundApiException)
          return;
        else if (error instanceof BaseApiException)
          return dispatch(apiErrorData(error));
        throw error;
      });
  };
};
