import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, CardHeader, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { MediumAvatar, LabelValueTypography } from '../../lib';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DataProviderEditLabel, DataProviderEditPosition, DataProviderEditNote } from './DataProviderEditDialog';
import ActionButtons from './shared/ActionButtons';
import GatewayBluetoothDialog from './GatewayBluetoothDialog';


const propTypes = {
  dataProvider: PropTypes.object.isRequired
};

const DataProviderCard = (props) => {
  const { t } = useTranslation();
  const [openRename, setOpenRename] = React.useState(false);
  const [openEditPosition, setOpenEditPosition] = React.useState(false);
  const [openAddNote, setOpenAddNote] = React.useState(false);
  const [openScan, setOpenScan] = React.useState(false);

  const handleRename = () => setOpenRename(true);
  const handleCloseRename = () => setOpenRename(false);

  const handleEditPosition = () => setOpenEditPosition(true);
  const handleCloseEditPosition = () => setOpenEditPosition(false);

  const handleAddNote = () => setOpenAddNote(true);
  const handleCloseAddNote = () => setOpenAddNote(false);


  const isGateway = props.dataProvider.provider_type == 'gateway';

  return (
    <React.Fragment>
      <Card { ...props }>
        <CardHeader
          avatar={
            <MediumAvatar alt="Travis Howard">{ (props.dataProvider.label.name || 'G')[0] }</MediumAvatar >
          }
          action={
            <PopupState variant="popover" popupId="demo-popup-menu">
              { (popupState) => (
                <React.Fragment>
                  <IconButton { ...bindTrigger(popupState) } >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    { ...bindMenu(popupState) }
                    anchorOrigin={ {
                      vertical: 'bottom',
                      horizontal: 'center',
                    } }
                    transformOrigin={ {
                      vertical: 'top',
                      horizontal: 'center',
                    } }>
                    <MenuItem onClick={ handleRename } >{ t("providers.provider_detail.rename") }</MenuItem>
                    <MenuItem onClick={ handleEditPosition } >{ t("providers.provider_detail.edit_position") }</MenuItem>
                    <MenuItem onClick={ handleAddNote } >{ t("providers.provider_detail.add_note") }</MenuItem>
                    <MenuItem onClick={ popupState.close } to={ `${props.dataProvider.id}/scenario` } component={ NavLink }>{ t("providers.provider_detail.add_scenario") }</MenuItem>
                  </Menu>
                  <DataProviderEditLabel initialDataProvider={ props.dataProvider } open={ openRename } handleClose={ handleCloseRename } />
                  <DataProviderEditPosition initialDataProvider={ props.dataProvider } open={ openEditPosition } handleClose={ handleCloseEditPosition } />
                  <DataProviderEditNote initialDataProvider={ props.dataProvider } open={ openAddNote } handleClose={ handleCloseAddNote } />
                </React.Fragment>
              ) }
            </PopupState>
          }
          title={
            <Typography gutterBottom variant="h5" component="h2">
              { props.dataProvider.label.name || "Unlabeled equipment" }
            </Typography>
          }
          subheader={ props.dataProvider.mac }
        />
        <CardContent>
          <LabelValueTypography label={ t("providers.provider_detail.identifier") } value={ props.dataProvider.identifier } />
          <LabelValueTypography label={ t("providers.provider_detail.type") } value={ props.dataProvider.provider_type } />
          <LabelValueTypography label={ t("providers.provider_detail.last_seen") } value={ new Date(props.dataProvider.last_seen).toDateString() } />
        </CardContent>
        <CardActions>
          <ActionButtons
            onBluetoothClick={ isGateway ? () => setOpenScan(true) : null }
            dataProvider={ props.dataProvider }
            displayMore={ false } />
        </CardActions>
      </Card>
      { isGateway && <GatewayBluetoothDialog gatewayId={ props.dataProvider.id } onClose={ () => setOpenScan(false) } open={ openScan } /> }
    </React.Fragment>
  );
};

DataProviderCard.propTypes = propTypes;

export default DataProviderCard;
