import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataServiceLastValue } from './actions';
import { lastValueMapByDataServiceIdSelector } from './selector';

/**
 *
 * @param {Number} dataServiceId - The data service ID
 * @returns {Object|undefined} last value object
 */
export const useDataLastValue = (dataServiceId) => {
  return useSelector(state => lastValueMapByDataServiceIdSelector(state)[dataServiceId]);
};

export const useDataLastValueFetcher = (dataServiceId) => {
  const dispatch = useDispatch();
  return React.useMemo(() => () => dispatch(fetchDataServiceLastValue(dataServiceId)), [dispatch, dataServiceId]);
};
