import Badge from '@material-ui/core/Badge';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import React from 'react';

const UnconnectedStyledBadge = withStyles((theme) => ({
  badge: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: 'red',
    color: 'red',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const ConnectedStyledBadge = withStyles(() => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(UnconnectedStyledBadge);

export const ConnectionBadge = ({ status, ...props }) => {
  return status ?
    (<ConnectedStyledBadge {...props}></ConnectedStyledBadge>) : (<UnconnectedStyledBadge {...props}></UnconnectedStyledBadge>);
};

ConnectionBadge.propTypes = {
  status: PropTypes.bool.isRequired
};
