import { Button, makeStyles } from '@material-ui/core';
import {
  Dashboard as DashboardIcon,
  FormatAlignJustify as FormatListBulletedIcon,
  Build as BuildIcon
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import AbstractAppbar from '../../../components/appbar/AbstractAppbar';
import AbstractMain from '../AbstractMainView';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root": {
      minWidth: "44px",
    },
    "& .MuiButton-root.active": {
      backgroundColor: theme.palette.secondary.main
    }
  }
}));

const propTypes = {
  cluster: PropTypes.object,
  title: PropTypes.string,
  rightAction: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number
  ])
};

const AbstractClusterView = ({ rightAction, title, ...props }) => {
  const classes = useStyles();
  return (
    <AbstractMain
      {...props}
      appBar={
        <AbstractAppbar
          title={title}
          leftAction={
            <div className={classes.root}>
              <Button color='primary' size="small" component={NavLink} to="dashboard">
                <DashboardIcon />
              </Button>
              <Button color='primary' size="small" component={NavLink} to="providers">
                <FormatListBulletedIcon />
              </Button>
              {/* <Button type="submit" size="small" color='primary' component={NavLink} to="map">
                <MapIcon />
              </Button> */}
              <Button type="submit" size="small" color='primary' component={NavLink} to="configuration">
                <BuildIcon />
              </Button>
            </div>
          }
          rightAction={rightAction}
        />
      }
    />
  );
};

AbstractClusterView.propTypes = propTypes;

export default AbstractClusterView;
