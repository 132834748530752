import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const EVENT_TYPES = [
  "unknown",
  "temperature",
  "battery_level",
  "humidity",
  "pressure",
  "rssi",
  "uart",
  "digital-output",
  "buzz",
  "image",
  "pir",
  "motion",
  "weight"
];

const OPERATOR = ['all', 'lower', 'higher', 'equal', 'not-equal'];

const useStyles = makeStyles((theme) => ({
  formLine: {
    marginBottom: theme.spacing(3)
  }
}));

const propTypes = {
  initialEvent: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

const EventEditDialog = ({ initialEvent, onSave, ...props }) => {
  const { t } = useTranslation();

  const style = useStyles();
  const [event, setEvent] = React.useState();
  useEffect(() => setEvent(initialEvent), [initialEvent]);
  if (!event)
    return (null);
  const handleSave = () => {
    onSave(event);
    props.onClose();
  };
  return (
    <Dialog {...props} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{event.id ? t("clusters.event.dialog.title") : t("clusters.event.dialog.title")}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>Create new cluster.</DialogContentText> */}
        <form>
          <FormGroup>
            <TextField
              variant="outlined"
              className={style.formLine}
              fullWidth
              onChange={e => setEvent({ ...event, name: e.target.value })}
              id="standard-error-helper-text"
              label={t("clusters.event.dialog.name")}
              defaultValue={event.name}
            // error={!event.name}
            // helperText={!event.name ? `Can not be blank.` : null}
            />
            <Grid container spacing={1} className={style.formLine}>
              <Grid item xs={3}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
                  <Select
                    label={t("clusters.event.dialog.type")}
                    value={event.event_type || ''}
                    onChange={e => setEvent({ ...event, "event_type": e.target.value })}
                    disabled={Boolean(event.id)}>
                    {EVENT_TYPES.map(eventType => (<option key={eventType} value={eventType}>{eventType}</option>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel htmlFor="outlined-age-native-simple">{t("clusters.event.dialog.operator")}</InputLabel>
                  <Select
                    label={t("clusters.event.dialog.operator")}
                    value={event.operator || ''}
                    onChange={e => setEvent({ ...event, operator: e.target.value })}>
                    {OPERATOR.map(operator => (<option key={operator} value={operator}>{operator}</option>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField fullWidth variant="outlined" size="small"
                  id="standard-error-helper-text"
                  label={t("clusters.event.dialog.value")}
                  value={event.value}
                  onChange={e => setEvent({ ...event, value: e.target.value })} />
              </Grid>
              <Grid item xs={3}>
                <TextField fullWidth variant="outlined" size="small"
                  id="standard-error-helper-text"
                  label={t("clusters.event.dialog.cooldown")}
                  value={event.tempo}
                  type="number"
                  onChange={e => setEvent({ ...event, tempo: e.target.value })} />
              </Grid>
            </Grid>
          </FormGroup>
          <TextField
            variant="outlined"
            className={style.formLine}
            fullWidth
            onChange={e => setEvent({ ...event, title: e.target.value })}
            id="standard-error-helper-text"
            label={t("clusters.event.dialog.header")}
            defaultValue={event.title}
            error={!event.title}
            helperText={!event.title ? t('form.errors.can_not_be_blank') : null} />
          <TextField
            variant="outlined"
            className={style.formLine}
            fullWidth
            onChange={e => setEvent({ ...event, message: e.target.value })}
            id="standard-error-helper-text"
            label={t("clusters.event.dialog.message")}
            multiline
            defaultValue={event.message}
            error={!event.message}
            helperText={!event.message ? t('form.errors.can_not_be_blank') : null} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} color="primary">
          {t("clusters.event.dialog.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary">
          {!event.id ? t("clusters.event.dialog.create") : t("clusters.event.dialog.edit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EventEditDialog.propTypes = propTypes;

export default EventEditDialog;
