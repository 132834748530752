import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}));

const propTypes = {
  open: PropTypes.bool.isRequired,
  initialCluster: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func
};

const defaultProps = {
  initialCluster: {
    name: '',
    description: ''
  },
  onSave: () => { },
  onClose: () => { }
};

const ClusterEditDialog = ({ initialCluster, open, onSave, onClose }) => {
  const { t } = useTranslation();
  const style = useStyles();
  const [cluster, setCluster] = React.useState({ ...initialCluster });
  React.useEffect(() => setCluster({ ...initialCluster }), [initialCluster, open]);
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{cluster.id ? t('clusters.edit.edit') : t('clusters.edit.create')} cluster</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>Create new cluster.</DialogContentText> */}
        <div className={style.formContainer}>
          <TextField
            fullWidth
            variant='outlined'
            onChange={event => setCluster({ ...cluster, name: event.target.value })}
            id="standard-error-helper-text"
            label={t('clusters.edit.name')}
            defaultValue={initialCluster.name}
            error={!cluster.name}
            helperText={!cluster.name ? t('form.errors.can_not_be_blank') : null} />
          <TextField
            fullWidth
            variant='outlined'
            id="outlined-multiline-flexible"
            label={t('clusters.edit.description')}
            multiline
            defaultValue={initialCluster.description}
            minRows={4}
            rowsMax={4}
            onChange={event => setCluster({ ...cluster, description: event.target.value })}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary">
          {t('clusters.edit.cancel')}
        </Button>
        <Button
          onClick={() => onSave(cluster)}
          color="primary">
          {!cluster.id ? t('clusters.edit.create') : t('clusters.edit.edit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ClusterEditDialog.propTypes = propTypes;
ClusterEditDialog.defaultProps = defaultProps;

export default ClusterEditDialog;
