import { dataProviderApi } from '../api';
import { BaseApiException } from '../api/exceptions';

const processError = (error) => {
  if (error instanceof BaseApiException
    && error.status > 300
    && error.status < 500)
    return error;
  throw error;
};

export const DATA_SERVICES_API_ERROR = 'DATA_SERVICES_API_ERROR';
const apiErrorDataServices = (error) => {
  return { type: DATA_SERVICES_API_ERROR, error };
};

// ===========================
// Fetch DataProvider Services
// ===========================

export const REQUEST_DATA_SERVICES = 'REQUEST_DATA_SERVICES';
const requestDataServices = (status) => {
  return { type: REQUEST_DATA_SERVICES, status };
};

export const RECEIVE_DATA_SERVICES = 'RECEIVE_DATA_SERVICES';
const receiveDataServices = (dataProviderId, dataServices) => {
  return { type: RECEIVE_DATA_SERVICES, dataProviderId, dataServices };
};

export const fetchDataServices = (dataProviderId) => {
  return (dispatch) => {
    dispatch(requestDataServices(true));
    return dataProviderApi.fetchDataServices(dataProviderId)
      .then(dataServices => dispatch(receiveDataServices(dataProviderId, dataServices.map(dataService => ({'data_provider_id': dataService.data_provider, ...dataService})))))
      .catch(error => dispatch(apiErrorDataServices(processError(error))))
      .finally(() => requestDataServices(false));
  };
};
