import * as localStoreManager from '../../libs/LocalStorageManager';
import { authApi, removeCommonAuth, setCommonAuth } from '../api';
import { AuthApiException } from '../api/exceptions';
import { enqueueSuccessNotification, enqueueErrorNotification } from '../moduleAppNotification/actions';

export const SIGN_OUT = 'SIGN_OUT';
export const signOut = () => {
  localStoreManager.RemoveToken();
  removeCommonAuth();
  return { type: SIGN_OUT };
};

export const ERROR_AUTH = 'ERROR_AUTH';
const errorAuth = (error) => {
  localStoreManager.RemoveToken();
  removeCommonAuth();
  return { type: ERROR_AUTH, error };
};

export const SET_AUTH_REDIRECT = 'SET_AUTH_REDIRECT';
export const setAuthRedirect = (authRedirect) => {
  return { type: SET_AUTH_REDIRECT, authRedirect };
};


export const REQUEST_TOKEN = 'REQUEST_TOKEN';
const requestToken = () => {
  return { type: REQUEST_TOKEN };
};

export const RECEIVE_TOKEN = 'RECEIVE_TOKEN';
const receiveAuth = (token) => {
  setCommonAuth(token);
  localStoreManager.SetToken(token);
  return { type: RECEIVE_TOKEN, token };
};

export const signIn = (login, password) => {
  return (dispatch) => {
    dispatch(requestToken());
    return authApi.login(login, password)
      .then(token => {
        dispatch(receiveAuth(token));
        dispatch(fetchUser(token));
        dispatch(enqueueSuccessNotification("Succesfully login"));
      })
      .catch(error => {
        dispatch(enqueueErrorNotification(error.message));
        if (!(error instanceof AuthApiException))
          throw error;
        dispatch(errorAuth(error));
      });
  };
};

export const signInByToken = (token) => {
  return (dispatch) => {
    dispatch(receiveAuth(token));
    dispatch(fetchUser(token));
  };
};

export const REQUEST_USER = 'REQUEST_USER';
const requestUser = () => {
  return { type: REQUEST_USER };
};

export const RECEIVE_USER = 'RECEIVE_USER';
const receiveUser = (user) => {
  return { type: RECEIVE_USER, user };
};

export const UPDATE_USER = 'UPDATE_USER';
const updateUser = (user) => {
  return { type: UPDATE_USER, user };
};

export const CREATE_NOTIFICATION_EMAIL_CHANGE = 'CREATE_NOTIFICATION_EMAIL_CHANGE';
const createNotificationEmailChange = (value) => {
  return { type: CREATE_NOTIFICATION_EMAIL_CHANGE, value };
};

export const DESTROY_NOTIFICATION_EMAIL_CHANGE = 'DESTROY_NOTIFICATION_EMAIL_CHANGE';
const destroyNotificationEmailChange = (value) => {
  return { type: DESTROY_NOTIFICATION_EMAIL_CHANGE, value };
};

const fetchUser = () => {
  return (dispatch) => {
    dispatch(requestUser());
    return authApi.fetchUser()
      .then(user => dispatch(receiveUser(user)))
      .catch(error => {
        if (!(error instanceof AuthApiException))
          throw error;
        dispatch(errorAuth(error));
      });

  };
};

// TODO: catch error
export const registerNotificationEmail = (email) => {
  return (dispatch) => {
    createNotificationEmailChange(true);
    return authApi.registerNotificationEmail(email)
      .then(() => {
        dispatch(enqueueSuccessNotification("Email has been sent"));
        dispatch(fetchUser());
      })
      .finally(() => createNotificationEmailChange(false));
  };
};

// TODO: catch error
export const unregisterNotificationEmail = (id) => {
  return (dispatch) => {
    destroyNotificationEmailChange(true);
    return authApi.unregisterNotificationEmail(id)
      .then(() => dispatch(fetchUser()))
      .finally(() => destroyNotificationEmailChange(false));
  };
};

// TODO: catch error
export const toggleNotificationEmail = (id, status) => {
  return (dispatch) => {
    createNotificationEmailChange(true);
    return authApi.toggleNotificationEmail(id, !status)
      .then(() => dispatch(fetchUser()))
      .finally(() => createNotificationEmailChange(false));
  };
};

// TODO: catch error
export const verifyNotificationEmail = (email) => {
  return (dispatch) => {
    createNotificationEmailChange(true);
    return authApi.verifyNotificationEmail(email)
      .finally(() => {
        dispatch(enqueueSuccessNotification("Email has been sent"));
        createNotificationEmailChange(false);
      });
  };
};

export const updateDataProviderLabelFavoris = (dataProviderLabelsFavoris) => {
  return (dispatch) => {
    dispatch(updateUser(true));
    return authApi.updateDataProviderLabelFavoris(dataProviderLabelsFavoris)
      .then(user => dispatch(receiveUser(user)))
      .finally(() => dispatch(updateUser(false)));
  };
};
