import { Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const MediumAvatar = withStyles((theme) => ({
  root: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}))(Avatar);

export const LargeAvatar = withStyles((theme) => ({
  root: {
    width: theme.spacing(20),
    height: theme.spacing(20)
  }
}))(Avatar);
