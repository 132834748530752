import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { dataRenderFormater } from '../../../libs/dataValueHelper';
import { useFreshness } from '../../../libs/utilHooks';
import { useDataLastValue } from '../../../modules/hooks';
import { translateDataServiceType } from '../../../libs/i18nHelper';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.8),
    width: '240px',
    maxWidth: '240px',
    borderRadius: '5px',
    backgroundColor: props => props.isFresh ? "#5b8a607a" : '',
    textDecoration: props => props.scratch ? 'line-through' : null
  }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 7,
    top: 7,
    border: `1px solid ${theme.palette.background.paper}`
  },
}))(Badge);

const useDataFreshnessStatus = (data, initial, timeout) => useFreshness(data ? data.date : null, initial, timeout);

const DataServiceChip = ({ dataService, scratch, onClick, ...props }) => {
  const dataLastValue = useDataLastValue(dataService.id);
  const isFresh = useDataFreshnessStatus(dataLastValue, false, 500);
  const classes = useStyles({ isFresh, scratch });
  const { renderIcon: DataServiceIcon, formatedData, friendlyDateString } = dataRenderFormater(dataService.type, dataLastValue, 'fr-FR');
  const onClickHandler = React.useCallback(() => onClick(dataService), [onClick, dataService]);
  return (
    <StyledBadge variant="dot" invisible={true}>
      <Chip
        size='medium'
        icon={<DataServiceIcon titleAccess={formatedData} fontSize='medium' />}
        label={(<Typography variant='inherit' color="primary"><b>{`${translateDataServiceType(dataService)} ${dataService.channel}: `}</b>{formatedData}</Typography>)}
        title={friendlyDateString}
        className={classes.chip}
        onClick={onClickHandler}
        {...props}
      />
    </StyledBadge>
  );
};

DataServiceChip.propTypes = {
  dataService: PropTypes.object.isRequired,
  scratch: PropTypes.bool,
  onClick: PropTypes.func
};

DataServiceChip.defaultProps = {
  scratch: false,
  onClick: () => { }
};

export default DataServiceChip;
