
export default class WebSocketHandler {
  constructor() {
    this.ws = null;
    this.messageListeners = [];
  }

  addOnMessageListener(listener) {
    if (!this.messageListeners.includes(listener))
      this.messageListeners.push(listener);
  }

  removeOnMessageListener(listener) {
    if (this.messageListeners.includes(listener))
      this.messageListeners.pop(listener);
  }

  initSocketConnection(url) {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    this.ws = new WebSocket(url);

    this.ws.onerror = (/*event*/) => {
      // console.info(event);
    };
    this.ws.onopen = (/*event*/) => {
      // console.info(event);
    };
    this.ws.onmessage = event => {
      const msg = JSON.parse(event.data);
      this.messageListeners.forEach(listener => listener(msg));
    };
    this.ws.onclose = (/*event*/) => {
      // console.info(event);
    };
  }

  closeSocketConnexion() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }
}
