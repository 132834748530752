import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    position: "absolute",
    left: 0,
    right: 0,
    /* width */
    "& ::-webkit-scrollbar": {
      width: "7px"
    },
    /* Track */
    "& ::-webkit-scrollbar-track": {
      background: "#f1f1f1"
    },

    /* Handle */
    "& ::-webkit-scrollbar-thumb": {
      background: "#888"
    },

    /* Handle on hover */
    "& ::-webkit-scrollbar-thumb:hover": {
      background: "#555"
    }
  },
  content: {
    paddingTop: (({ paddingTop }) => theme.spacing(paddingTop)),
    height: `calc(100% - ${theme.secondaryAppBarHeight}px)`,
    overflow: 'auto'
  }
}));


const AbstractMainView = ({ appBar, app, paddingTop = 2 }) => {
  const classes = useStyles({ paddingTop });
  return (
    <div className={classes.root}>
      {appBar}
      <div className={classes.content}>
        {app}
      </div>
    </div>
  );
};

AbstractMainView.propTypes = {
  paddingTop: PropTypes.number,
  appBar: PropTypes.node,
  app: PropTypes.node
};

export default AbstractMainView;
