import React from 'react';
import PropTypes from 'prop-types';
import { ConnectionBadge } from '../../lib';
import { Box, Icon, IconButton, Typography } from '@material-ui/core';
import { DataProviderMaterialIconDriver, DataProviderMetaName } from '../../../libs/dataProvider';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export const DataProviderMinimalInfo = ({dataProvider, openStatus, onOpenClick, disabledOpen, ...props}) => {
  return (
    <Box {...props}>
      <Typography gutterBottom color="primary">
        {!onOpenClick ? null :
          <IconButton disabled={disabledOpen} size="small" onClick={() => { if (onOpenClick) onOpenClick(); }}>
            {openStatus ? <ExpandMore /> : <ExpandLess />}
          </IconButton>}
        <ConnectionBadge
          status={dataProvider.is_online}
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }} variant="dot" >
          <Icon size='small'>{DataProviderMaterialIconDriver(dataProvider.provider_type)}</Icon>
        </ConnectionBadge>
        <b>{dataProvider.label ? dataProvider.label.name : 'Unlabeled'}</b>
      </Typography>
      <Typography variant="body2" >{DataProviderMetaName(dataProvider)}</Typography>
      <Typography variant="caption">{dataProvider.mac}</Typography>
    </Box>
  );
};

DataProviderMinimalInfo.propTypes = {
  dataProvider: PropTypes.object.isRequired,
  onOpenClick: PropTypes.bool,
  disabledOpen: PropTypes.bool,
  openStatus: PropTypes.bool
};

// export default DataProviderMapPopUp;
