import * as Actions from '../actions';

const initState = () => {
  return {
    isFetchingAuth: false,
    isFetchingUser: false,
    isUpdatingUser: false,
    isCreatingNotificationEmail: false,
    isDestroyingNotificationEmail: false,
    token: null,
    user: null,
    error: null,
    authRedirect: '/'
  };
};

export default (state = initState(), action) => {
  switch (action.type) {
    case Actions.REQUEST_TOKEN:
      state = { ...state, isFetchingAuth: true };
      break;
    case Actions.RECEIVE_TOKEN:
      state = {
        ...state,
        isFetchingAuth: false,
        token: action.token
      };
      break;
    case Actions.REQUEST_USER:
      state = { ...state, isFetchingUser: true };
      break;
    case Actions.RECEIVE_USER:
      state = {
        ...state,
        isFetchingUser: false,
        user: action.user
      };
      break;
    case Actions.UPDATE_USER:
      state = {
        ...state,
        isUpdatingUser: action.value,
      };
      break;
    case Actions.CREATE_NOTIFICATION_EMAIL_CHANGE:
      state = {
        ...state,
        isCreatingNotificationEmail: action.value,
      };
      break;
    case Actions.DESTROY_NOTIFICATION_EMAIL_CHANGE:
      state = {
        ...state,
        isDestroyingNotificationEmail: action.value,
      };
      break;
    case Actions.SIGN_OUT:
      state = initState();
      break;
    case Actions.ERROR_AUTH:
      console.warn(action.error);
      state = initState();
      state = {
        ...state,
        error: action.error
      };
      break;
    case Actions.SET_AUTH_REDIRECT:
      state = {
        ...state,
        authRedirect: action.authRedirect
      };
      break;
    default:
      break;
  }
  return state;
};
