import { ClickAwayListener, Divider, Fade, IconButton, InputBase, List, ListItem, ListItemText, Paper, Popper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FilterIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AbstractAppbar from './AbstractAppbar';

const propTypes = {
  onSearchChange: PropTypes.func,
  onClusterCreateClick: PropTypes.func
};

const defaultProps = {
  onSearchChange: () => { },
  onClusterCreateClick: () => { }
};

const ClustersAppbar = ({ onSearchChange, onClusterCreateClick }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  return (
    <AbstractAppbar
      title={t('clusters.list.subtitle')}
      rightAction={
        <React.Fragment>
          <Divider orientation="vertical" flexItem />
          <IconButton type="submit" aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            placeholder={t('clusters.list.search_cluster')}
            onChange={onSearchChange}
          />
          <Divider orientation="vertical" flexItem />
          <IconButton onClick={handleClick}>
            <FilterIcon />
          </IconButton>
          <ClickAwayListener onClickAway={() => setOpen(true)}>
            <Popper open={open} anchorEl={anchorEl} placement={"bottom-end"} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <List>
                      <ListItem>
                        <ListItemText primary={"All"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={"Ownered"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={"Favorite"} />
                      </ListItem>
                      {/* <Typography className={classes.typography}>The content of the Popper.</Typography> */}
                    </List>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
          <Divider orientation="vertical" flexItem />
          <IconButton onClick={onClusterCreateClick}>
            <AddIcon />
          </IconButton>
          <Divider orientation="vertical" flexItem />
        </React.Fragment>
      }
    >
    </AbstractAppbar>
  );
};

ClustersAppbar.propTypes = propTypes;
ClustersAppbar.defaultProps = defaultProps;

export default ClustersAppbar;
