import React from 'react';
import { Route, Switch, useParams } from "react-router-dom";
import { Spinner } from '../../components/lib';
import config from "../../config";
import { ClusterContext, useCluster, useClusterContext, useClustersIsFetching, useFetchClusterEventConfigurations, useFetchClusterEventsDocument, useFetchClusterMetrics, useSetNavTitle, useToken, WebSocketDataContext } from '../../modules/hooks';
import DashboardView from './cluster/DashboardView';
import MapView from './cluster/MapView';
import ConfigurationView from './cluster/ConfigurationView';
import ProviderListView from './cluster/ProviderListView';
import ProviderDetailView from './cluster/ProviderDetailView';
import ServiceDetailView from './cluster/ServiceDetailView';
import ProviderScenarioView from './cluster/ProviderScenariosView';

const URL_CLUSTER_ID_KEY = 'clusterId';

const useClusterFetchRelatedModel = (cluster) => {
  const fetchClusterEventsDocument = useFetchClusterEventsDocument();

  // fetch once on mount
  React.useEffect(() => {
    fetchClusterEventsDocument(cluster.id);
  }, [cluster.id]);
};

const useClusterConnectWS = (cluster) => {
  const token = useToken();
  const webSocketDataHandler = React.useContext(WebSocketDataContext);
  React.useEffect(() => {
    webSocketDataHandler.initSocketConnection(`${config.VUE_APP_BRIDGE_SOCKET_URL}/application/cluster/${cluster.id}/data/?token=${token}`);
    return () => webSocketDataHandler.closeSocketConnexion();
  }, [[cluster.id, webSocketDataHandler]]);
};

const _ClusterViewRouter = () => {
  const cluster = useClusterContext();

  useFetchClusterMetrics(cluster.id);
  useFetchClusterEventConfigurations(cluster.id);

  useSetNavTitle(cluster.name);
  useClusterFetchRelatedModel(cluster);
  useClusterConnectWS(cluster);

  return (
    <Switch>
      <Route exact path='/cluster/:clusterId/dashboard'>
        <DashboardView />
      </Route>
      <Route exact path='/cluster/:clusterId/providers'>
        <ProviderListView />
      </Route>
      <Route exact path='/cluster/:clusterId/provider/:dataProviderId'>
        <ProviderDetailView />
      </Route>
      <Route exact path='/cluster/:clusterId/provider/:dataProviderId/scenarios'>
        <ProviderScenarioView />
      </Route>
      <Route exact path='/cluster/:clusterId/provider/:dataProviderId/service/:dataServiceId'>
        <ServiceDetailView />
      </Route>
      <Route exact path='/cluster/:clusterId/map'>
        <MapView />
      </Route>
      <Route exact path='/cluster/:clusterId/configuration'>
        <ConfigurationView />
      </Route>
    </Switch>);
};


const ClusterViewRouter = () => {
  const clustersIsFetching = useClustersIsFetching();
  const cluster = useCluster(parseInt(useParams()[URL_CLUSTER_ID_KEY]));

  if (clustersIsFetching)
    return <Spinner />;

  if (!cluster)
    return 'cluster not found';

  return (
    <ClusterContext.Provider value={ cluster }>
      <_ClusterViewRouter />
    </ClusterContext.Provider>
  );
};

export default ClusterViewRouter;
