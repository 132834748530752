import React, { useReducer } from 'react';

export const useInterval = (callback, interval) => {
  const savedCallback = React.useRef();
  React.useEffect(
    () => savedCallback.current = callback,
    [callback]
  );
  React.useEffect(() => {
    const handler = (...args) => savedCallback.current(...args);
    const id = window.setInterval(handler, interval);
    handler();
    return () => window.clearInterval(id);
  }, [interval]);
};

export const useFreshness = (observe, initial, timeout) => {
  const [isFresh, setIsFresh] = React.useState(initial);
  React.useEffect(() => {
    const token = setIsFresh(Boolean(observe));
    setTimeout(() => setIsFresh(false), timeout);
    return () => clearTimeout(token);
  } , [observe, timeout]);
  return isFresh;
};


const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        data: action.data,
        isLoading: false
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        data: null,
        isLoading: false
      };
    default:
      throw new Error();
  }
};

export const useFetcher = (asynCallback, triggers = undefined) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    data: null,
    isLoading: false
  });

  React.useEffect(() => {
    let didCancel = false;
    async function fetchData() {
      dispatch({ type: 'FETCH_INIT' });
      try {
        const result = await asynCallback();
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', data: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE' });
        }
      }
    }
    fetchData();
    return () => { didCancel = true; };
  }, triggers);
  return [state.data, state.isLoading];
};
