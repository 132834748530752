import { doGet, doPatch } from './base';

export default {
  fetchAll() {
    return doGet('/api/client/gateway/');
  },
  fetch(gatewayId) {
    return doGet(`/api/client/gateway/${gatewayId}/`);
  },
  fetchScenarios(gatewayId) {
    return doGet(`/api/client/gateway/${gatewayId}/scenarios`);
  },
  patchLocation(gatewayId, location) {
    return doPatch(`/api/client/gateway/${gatewayId}/location/`, { body: location });
  }
};
