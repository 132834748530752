import { autonomousDeviceApi, dataProviderApi, deviceApi, gatewayApi, gatewayCmdApi } from '../api';
import { BaseApiException } from '../api/exceptions';

const processError = (error) => {
  if (error instanceof BaseApiException
    && error.status > 300
    && error.status < 500)
    return error;
  throw error;
};

export const DATA_PROVIDER_API_ERROR = 'DATA_PROVIDER_API_ERROR';
const dataProviderApiError = (error) => {
  return { type: DATA_PROVIDER_API_ERROR, error };
};

export const UPDATE_DATA_PROVIDER_BEGIN = 'UPDATE_DATA_PROVIDER_BEGIN';
const updateDataProviderBegin = () => {
  return { type: UPDATE_DATA_PROVIDER_BEGIN };
};

export const UPDATE_DATA_PROVIDER_RECEIVED = 'UPDATE_DATA_PROVIDER_RECEIVED';
const updateDataProviderReceived = (dataProvider) => {
  return { type: UPDATE_DATA_PROVIDER_RECEIVED, dataProvider };
};


// ============================
// Upsert DataProvider Label
// ============================
export const updateDataProviderLabel = (dataProvider, label) => {
  return (dispatch) => {
    dispatch(updateDataProviderBegin());
    return dataProviderApi.patchDataProviderLabel(dataProvider.id, label)
      .then(newDataProvider => dispatch(updateDataProviderReceived(newDataProvider)))
      .catch(error => dispatch(dataProviderApiError(processError(dispatch, error))));
  };
};


// ============================
// Upsert DataProvider Note
// ============================
export const updateDataProviderNote = (dataProvider, note) => {
  return (dispatch) => {
    dispatch(updateDataProviderBegin());
    return dataProviderApi.patchDataProvider(dataProvider.id, { note })
      .then(newDataProvider => dispatch(updateDataProviderReceived(newDataProvider)))
      .catch(error => dispatch(dataProviderApiError(processError(dispatch, error))));
  };
};


// ============================
// Upsert DataProvider Position
// ============================
export const updateDataProviderPosition = (dataProvider, lat, lon) => {
  return (dispatch) => {
    dispatch(updateDataProviderBegin());
    return dataProviderApi.patchDataProvider(dataProvider.id, { lat, lon })
      .then(newDataProvider => dispatch(updateDataProviderReceived(newDataProvider)))
      .catch(error => dispatch(dataProviderApiError(processError(dispatch, error))));
  };
};


// ============================
// Fetch DataProvider Gaterways
// ============================

export const REQUEST_GATEWAYS = 'REQUEST_GATEWAYS';
const requestGateways = () => {
  return { type: REQUEST_GATEWAYS };
};

export const RECEIVE_GATEWAYS = 'RECEIVE_GATEWAYS';
const receiveGateways = (gateways) => {
  return { type: RECEIVE_GATEWAYS, gateways };
};

export const fetchGateway = () => {
  return (dispatch) => {
    dispatch(requestGateways());
    return gatewayApi.fetchAll()
      .then(gateways => {
        dispatch(receiveGateways(gateways));
        gateways.forEach(dp => {
          dispatch(fetchDPErrors(dp.id));
        });
      })
      .catch(error => dispatch(dataProviderApiError(processError(error))));
  };
};

// ==========================
// Fetch DataProvider Devices
// ==========================

export const REQUEST_DEVICES = 'REQUEST_DEVICES';
const requestDevices = () => {
  return { type: REQUEST_DEVICES };
};

export const RECEIVE_DEVICES = 'RECEIVE_DEVICES';
const receiveDevices = (devices) => {
  return { type: RECEIVE_DEVICES, devices };
};

export const fetchDevice = () => {
  return (dispatch) => {
    dispatch(requestDevices());
    return deviceApi.fetchAll()
      .then(devices => {
        dispatch(receiveDevices(devices));
        devices.forEach(dp => {
          dispatch(fetchDPErrors(dp.id));
        });
      })
      .catch(error => dispatch(dataProviderApiError(processError(error))));
  };
};


// ===================================
// Fetch DataProvider AutonomouDevices
// ===================================

export const REQUEST_AUTONOMOUS_DEVICES = 'REQUEST_AUTONOMOUS_DEVICES';
const requestAutonomousDevices = () => {
  return { type: REQUEST_AUTONOMOUS_DEVICES };
};

export const RECEIVE_AUTONOMOUS_DEVICES = 'RECEIVE_AUTONOMOUS_DEVICES';
const receiveAutonomousDevices = (autonomousDevices) => {
  return { type: RECEIVE_AUTONOMOUS_DEVICES, autonomousDevices };
};

export const fetchAutonomousDevices = () => {
  return (dispatch) => {
    dispatch(requestAutonomousDevices());
    return autonomousDeviceApi.fetchAll()
      .then(autonomousDevices => dispatch(receiveAutonomousDevices(autonomousDevices)))
      .catch(error => dispatch(dataProviderApiError(processError(error))));
  };
};


// ==========================
// Fetch DataProviders Errors
// ==========================

export const REQUEST_DP_ERRORS = 'REQUEST_DP_ERRORS';
const requestDPErrors = () => {
  return { type: REQUEST_DP_ERRORS };
};

export const RECEIVE_DP_ERRORS = 'RECEIVE_DP_ERRORS';
const receiveDPErrors = (dataProviderId, dpErrors) => {
  return { type: RECEIVE_DP_ERRORS, dataProviderId, dpErrors };
};

export const fetchDPErrors = (dataProviderId) => {
  return (dispatch) => {
    dispatch(requestDPErrors());
    return dataProviderApi.fetchDataProviderErrors(dataProviderId)
      .then(dpErrors => dispatch(receiveDPErrors(dataProviderId, dpErrors)))
      .catch(error => {
        if (!(error instanceof BaseApiException && error.status === 404))
          throw error;
        // dispatch(dataProviderApiError(processError(error)));
      });
  };
};


// =======================
// Gateway Command Actions
// =======================
export const sendCmdArmModeChange = (gatewayId, newArmMode) => {
  return (/* dispatch */) => {
    return gatewayCmdApi.sendSetArmMode(gatewayId, newArmMode)
      .catch(() => { });
  };
};

// const processError = (error) => {
//   if (error instanceof BaseApiException
//     && error.status > 300
//     && error.status < 500)
//     return error;
//   throw error;
// };

// export const REQUEST_DEVICES = 'REQUEST_DEVICES';
// const requestDevices = () => {
//   return { type: REQUEST_DEVICES };
// };

// export const RECEIVE_DEVICES = 'RECEIVE_DEVICES';
// const receiveDevices = (devices) => {
//   return { type: RECEIVE_DEVICES, devices };
// };

// export const ERROR_DEVICES = 'ERROR_DEVICES';
// const errorDevices = (error) => {
//   return { type: ERROR_DEVICES, error };
// };

export const sendCmdBluetoothScanOn = (gatewayId) => {
  return (/* dispatch */) => {
    return gatewayCmdApi.sendBluetoothScanOn(gatewayId)
      .catch(/* error => dispatch(errorGateways(processError(error))) */);
  };
};

export const bluetoothDevicePair = (gatewayId, mac, connectivity) => {
  return (/* dispatch */) => {
    // dispatch(requestDevices());
    return gatewayCmdApi.sendBluetoothDevicePair(gatewayId, mac, connectivity)
      // .then(devices => dispatch(receiveDevices(devices)))
      .catch(/* error => dispatch(errorDevices(processError(error))) */);
  };
};

export const bluetoothDeviceUnpair = (gatewayId, mac) => {
  return (/* dispatch */) => {
    // dispatch(requestDevices());
    return gatewayCmdApi.sendBluetoothDeviceUnpair(gatewayId, mac)
      // .then(devices => dispatch(receiveDevices(devices)))
      .catch(/* error => dispatch(errorDevices(processError(error))) */);
  };
};
