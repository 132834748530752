import { createSelector } from 'reselect';

export const deviceMapSelector = state => state.dataProviders.devices;

export const deviceListSelector = createSelector(
  deviceMapSelector,
  devicesMap => Object.values(devicesMap)
);

/**
* Build a map of device list by gateway id
*/
export const devicesMapByGatewayIdSelector = createSelector(
  deviceListSelector,
  devices => devices.reduce((res, device) => {
    res[device.gateway_id] = res[device.gateway_id] || [];
    res[device.gateway_id].push(device);
    return res;
  }, {})
);

/**
* Build a map of device list by cluster id
*/
export const devicesMapByClusterIdSelector = createSelector(
  deviceListSelector,
  devices => devices.reduce((res, device) => {
    res[device.cluster_id] = res[device.cluster_id] || [];
    res[device.cluster_id].push(device);
    return res;
  }, {})
);

/**
* Build a map of scanned bluetooth devices list by gateway id
*/
export const scannedBluetoothDevicesMapByGatewayIdSelector = state => state.dataProviders.scannedBluetoothDevicesByGatewayId;


export const gatewayMapSelector = state => state.dataProviders.gateways;

export const gatewayListSelector = createSelector(
  gatewayMapSelector,
  gateways => Object.values(gateways)
);

/**
* Build a map of gateway list by cluster id
*/
export const gatewaysMapByClusterIdSelector = createSelector(
  gatewayListSelector,
  gateways => gateways.reduce((res, gateway) => {
    res[gateway.cluster_id] = res[gateway.cluster_id] || [];
    res[gateway.cluster_id].push(gateway);
    return res;
  }, {})
);

export const autonomousDeviceMapSelector = state => state.dataProviders.autonomousDevices;

export const autonomousDeviceListSelector = createSelector(
  autonomousDeviceMapSelector,
  autonomousDevices => Object.values(autonomousDevices)
);

/**
* Build a map of autonomous-devices list by cluster id
*/
export const autonomousDevicesMapByClusterIdSelector = createSelector(
  autonomousDeviceListSelector,
  autonomousDevices => autonomousDevices.reduce((res, autonomousDevice) => {
    res[autonomousDevice.cluster_id] = res[autonomousDevice.cluster_id] || [];
    res[autonomousDevice.cluster_id].push(autonomousDevice);
    return res;
  }, {})
);


export const dataProviderMapSelector = createSelector(
  gatewayMapSelector,
  deviceMapSelector,
  autonomousDeviceMapSelector,
  (gatewayMap, deviceMap, autonomousDevices) => ({ ...gatewayMap, ...deviceMap, ...autonomousDevices })
);

export const dataProviderListSelector = createSelector(
  dataProviderMapSelector,
  dataProviderMap => Object.values(dataProviderMap)
);

export const dataProvidersMapByClusterIdSelector = createSelector(
  dataProviderListSelector,
  dataProviders => dataProviders.reduce((res, dataProvider) => {
    res[dataProvider.cluster_id] = res[dataProvider.cluster_id] || [];
    res[dataProvider.cluster_id].push(dataProvider);
    return res;
  }, {})
);

export const dataProviderErrorsMapSelector = state => state.dataProviders.dataProviderErrors;
