import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createClusterEventConfig, deleteClusterEventConfig, fetchClusterEventConfigurations, linkClusterEventConfigToDataService, unlinkClusterEventConfigToDataService, updateClusterEventConfig } from './actions';
import { clusterEventConfigurationsMapSelector, clusterEventConfigurationsIsFetchingSelector, clusterEventConfigurationsIsCreatingSelector, clusterEventConfigurationsIsUpdatingSelector, clusterEventConfigurationsIsDeletingSelector } from './selectors';

// Use to keep an empty array reference
const EMPTY_ARRAY = Object.freeze([]);

export const useClusterEventConfigurationsRequestStatuses = () => {
  const isFetching = useSelector(state => clusterEventConfigurationsIsFetchingSelector(state));
  const isCreating = useSelector(state => clusterEventConfigurationsIsCreatingSelector(state));
  const isUpdating = useSelector(state => clusterEventConfigurationsIsUpdatingSelector(state));
  const isDeleting = useSelector(state => clusterEventConfigurationsIsDeletingSelector(state));
  return [isFetching, isCreating, isUpdating, isDeleting];
};

export const useClusterEventConfigurations = (clusterId) => {
  return useSelector(state => clusterEventConfigurationsMapSelector(state)[clusterId]) || EMPTY_ARRAY;
};

export const useFetchClusterEventConfigurations = (clusterId) => {
  const dispatch = useDispatch();
  React.useEffect(() => { dispatch(fetchClusterEventConfigurations(clusterId)); }, [clusterId]);
};

export const useCreateClusterEventConfigurationsCallback = () => {
  const dispatch = useDispatch();
  return React.useCallback((clusterId, clusterEventConfiguration) => dispatch(createClusterEventConfig(clusterId, clusterEventConfiguration)), [dispatch]);
};

export const useUpdateClusterEventConfigurationsCallback = () => {
  const dispatch = useDispatch();
  return React.useCallback((clusterId, clusterEventConfiguration) => dispatch(updateClusterEventConfig(clusterId, clusterEventConfiguration)), [dispatch]);
};

export const useDeleteClusterEventConfigurationsCallback = () => {
  const dispatch = useDispatch();
  return React.useCallback((clusterId, clusterEventConfiguration) => dispatch(deleteClusterEventConfig(clusterId, clusterEventConfiguration)), [dispatch]);
};

export const useLinkClusterEventConfigurationsToDataServiceCallback = () => {
  const dispatch = useDispatch();
  return React.useCallback((clusterId, clusterEventConfigurationId, dataServiceId) => dispatch(linkClusterEventConfigToDataService(clusterId, clusterEventConfigurationId, dataServiceId)), [dispatch]);
};

export const useUnlinkClusterEventConfigurationsToDataServiceCallback = () => {
  const dispatch = useDispatch();
  return React.useCallback((clusterId, clusterEventConfigurationId, dataServiceId) => dispatch(unlinkClusterEventConfigToDataService(clusterId, clusterEventConfigurationId, dataServiceId)), [dispatch]);
};
