import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const DEFAULT_VARIANT = 'body1';
const DEFAULT_COLOR = "textSecondary";

const styles = () => ({
  root: {
    margin: 0,
  }
});

const propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  upperLabel: PropTypes.bool
};

export const LabelValueTypography = withStyles(styles)(({ label, value, upperLabel, ...props }) => {
  return (
    <Typography {...{ variant: DEFAULT_VARIANT, color: DEFAULT_COLOR, ...props }}>
      <div style={{ display: 'inline-block', width: '120px' }}><strong>{upperLabel ? label.toUpperCase() : label} : </strong></div>
      <div style={{ display: 'inline-block' }}>{value}</div>
    </Typography >);
});

LabelValueTypography.propTypes = propTypes;
