import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import App from './App';
import Notifier from './components/utils/Notifier';
import config from "./config";
import './index.css';
import i18n from './locales';
import { reformatDataValue } from './libs/dataValueHelper';
import * as localStoreManager from './libs/LocalStorageManager';
import WebSocketHandler from './libs/WebSocket';
import { signInByToken } from './modules/actions';
import { WebSocketContext, WebSocketDataContext } from './modules/hooks';
import { receiveLastValue } from './modules/moduleData/actions';
import { generateSubscriberHandler, rootReducer } from './modules/reducer';
import { isConnectedSelector } from './modules/selectors';
import reportWebVitals from './reportWebVitals';


// =========================
// Init Application language
// =========================
i18n.changeLanguage(navigator.language);

// =========================
// Redux Store configuration
// =========================
const rr = rootReducer();
const store = createStore(rr, composeWithDevTools(applyMiddleware(thunk)));

// =======================
// Websocket configuration
// =======================

const webSocketHandler = new WebSocketHandler();
store.subscribe(generateSubscriberHandler(store, isConnectedSelector, (isConnected) => {
  if (isConnected) webSocketHandler.initSocketConnection(`${config.VUE_APP_BRIDGE_SOCKET_URL}/application/enduser/state?token=${store.getState().auth.token}`);
  else webSocketHandler.closeSocketConnexion();
}));

const webSockeDatatHandler = new WebSocketHandler();
webSockeDatatHandler.addOnMessageListener(msg => {
  const dataLastValue = reformatDataValue(msg.service_id, msg.value);
  store.dispatch(receiveLastValue(msg.service_id, dataLastValue));
});

// =================
// Storage conection
// =================
const token = localStoreManager.GetToken();
if (token) store.dispatch(signInByToken(token));

ReactDOM.render(
  <WebSocketContext.Provider value={webSocketHandler}>
    <WebSocketDataContext.Provider value={webSockeDatatHandler}>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <Notifier />
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </SnackbarProvider>
      </Provider>
    </WebSocketDataContext.Provider>
  </WebSocketContext.Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
