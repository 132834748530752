import React from 'react';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import { Map, MapMarker } from '../../../lib';
import { DataProviderMaterialIconDriver } from '../../../../libs/dataProvider';
import DataProviderMapPopUp from './DataProviderMapPopUp';

const DataProviderMap = ({mapRef, dataProviders, ...props}) => {
  const map = React.useRef(null);
  React.useEffect(() => {
    // Propagate the ref if needed
    if (mapRef)
      mapRef.current = map.current;

    if (map.current && dataProviders.length > 0) {
      if (dataProviders.length > 0) {
        if (dataProviders.length > 1) {
          const bounds = dataProviders.reduce(
            (bds, dp) => bds.extend([dp.lon, dp.lat]),
            new mapboxgl.LngLatBounds([[dataProviders[0].lon, dataProviders[0].lat], [dataProviders[0].lon, dataProviders[0].lat]]));
          map.current.fitBounds(bounds, { padding: { top: 50, bottom: 50, left: 50, right: 50 } });
        } else {
          map.current.flyTo({ center: [dataProviders[0].lon, dataProviders[0].lat], zoom: 15 });
        }
      }
    }
  }, [map.current, dataProviders.map(dp => dp.id).toString()]);

  return (
    <Map mapRef={map} {...props}>
      {dataProviders.map((dp) =>
        <MapMarker
          key={dp.id}
          icon={DataProviderMaterialIconDriver(dp.provider_type)}
          lon={dp.lon}
          lat={dp.lat}>
          <DataProviderMapPopUp closeOnClick dataProvider={dp} />
        </MapMarker>)}
    </Map>
  );
};

DataProviderMap.propTypes = {
  mapRef: PropTypes.object,
  dataProviders: PropTypes.array
};

DataProviderMap.defaultProps = {
  dataProviders: []
};


export default DataProviderMap;
