import React from 'react';
import { Container, Divider, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useClusterContext } from '../../../modules/hooks';
import AbstractClusterView from './AbstractClusterView';
import { MapCard, EventsTimelineCard, ClusterTitleCard, DataInfoCard, EventInfoCard, MaterialInfoCard, PhotoInfoCard } from '../../../components/clusterDetail/dashboard';

const SPACING = 2;

const DashboardView = () => {
  const { t } = useTranslation();
  const cluster = useClusterContext();
  return (
    <AbstractClusterView
      title={ t("clusters.detail.title") }
      app={
        <Container>
          <Grid container spacing={ SPACING }>
            <Grid item xs={ 12 }>
              <ClusterTitleCard cluster={ cluster } editable />
              <Divider />
            </Grid>
            <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
              <MaterialInfoCard />
            </Grid>
            <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
              <EventInfoCard />
            </Grid>
            <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
              <DataInfoCard />
            </Grid>
            <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
              <PhotoInfoCard />
            </Grid>
            <Grid item xs={ 12 } md={ 7 } >
              <div style={ { height: '412px' } }>
                <MapCard />
              </div>
            </Grid>
            <Grid item xs={ 12 } md={ 5 }>
              <div style={ { height: '412px' } }>
                <EventsTimelineCard clusterId={ cluster.id } events={ cluster.events } />
              </div>
            </Grid>
          </Grid>
        </Container>
      }
    />);
};

export default DashboardView;
