
import { Collapse, Divider, Drawer, Hidden, Link, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ClusterIcon from '@material-ui/icons/GroupWorkOutlined';
import HomeWorkIcon from '@material-ui/icons/HomeWorkOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import StarBorder from '@material-ui/icons/StarBorder';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/logo/we-sens.png';
import config from '../../config';
import { signOut } from '../../modules/actions';
import { useClusterList } from '../../modules/moduleCluster/hooks';

const NavbarListItem = ({ icon: Icon, text, ...props }) => {
  return (
    <ListItem button {...props}>
      <ListItemIcon><Icon fontSize="large" /></ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ variant: "body1" }} />
      {props.children}
    </ListItem>);
};

NavbarListItem.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.element
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: theme.drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    color: theme.palette.navbar.contrastText,
    "& .MuiListItemIcon-root": {
      color: theme.palette.navbar.contrastText
    },
    "& .MuiListItem-button.active": {
      backgroundColor: theme.palette.navbar.main
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: theme.palette.navbar.light
    },
    backgroundColor: theme.palette.navbar.dark,
    width: theme.drawerWidth,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    padding: theme.spacing(1.2),
    "& a": {
      color: theme.palette.navbar.contrastText,
    },
    "& .navbarLogo": {
      width: '60%',
      marginBottom: theme.spacing(0.5),
    },
    "& .MuiLink-root": {
      display: 'block'
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const propTypes = {
  mobileOpen: PropTypes.bool,
  onClose: PropTypes.func
};

const Navbar = ({ mobileOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const clusters = useClusterList();

  const [open, setOpen] = React.useState(true);
  const handleClick = () => setOpen(!open);
  const handleLogout = () => dispatch(signOut());
  const drawer = (
    <div>
      <div className={classes.toolbar} >
        <Link href="#"><img className="navbarLogo" alt="we-sens" title="we-sens" src={Logo} /></Link>
        <Link href="#">{`version: ${config.VERSION}`}</Link>
      </div>
      <Divider />
      <List disablePadding>
        <NavbarListItem icon={HomeWorkIcon} text={t('appbar.clusters')} component={NavLink} to="/" exact />
        <NavbarListItem icon={StarBorder} text={t('appbar.watchlist')} onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </NavbarListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {clusters.map(cluster => {
              const basePath = `/cluster/${cluster.id}`;
              return (
                <ListItem dense key={cluster.id} button className={classes.nested} to={`${basePath}/dashboard`}
                  component={NavLink}
                  isActive={(match, location) => location.pathname.includes(basePath)}>
                  <ListItemIcon>
                    <ClusterIcon />
                  </ListItemIcon>
                  <ListItemText primary={cluster.name} />
                </ListItem>);
            })}
          </List>
        </Collapse>
      </List>
      <Divider />
      <List disablePadding>
        <NavbarListItem icon={AccountIcon} text={t('appbar.account')} component={NavLink} to="/account" />
        <NavbarListItem icon={SettingsIcon} text={t('appbar.settings')} component={NavLink} to="/settings" />
        <NavbarListItem icon={ExitToAppIcon} text={t('appbar.logout')} onClick={handleLogout} />
      </List>
    </div>
  );

  const container = window !== undefined ? () => window.document.body : undefined;
  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={onClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>);
};

Navbar.propTypes = propTypes;

export default Navbar;
