import { Badge, Box, CardContent, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import React from 'react';
import { useAuthUser } from '../../modules/hooks';
import { LargeAvatar } from '../lib';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    position: 'relative'
  },
  editButton: { position: 'absolute', top: theme.spacing(2), right: theme.spacing(2) },
  avatarBadge: {
    margin: 'auto',
    display: 'table',
  }
}));

export default function UserDataForm() {
  const auth = useAuthUser();
  const classes = useStyles();


  return (
    <Box className={ classes.root }>
      <IconButton className={ classes.editButton }><EditIcon /></IconButton>
      <CardContent>
        <Badge
          className={ classes.avatarBadge }
          overlap='circular'
          anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'right',
          } }
          badgeContent={ <AddAPhotoIcon /> }
        >
          <LargeAvatar />
        </Badge>
        <Typography variant='h4' align='center'>
          { auth.first_name } { auth.last_name }

        </Typography>
        <Typography variant='h6' align='center'>
          { auth.username }
        </Typography>
        <Typography variant='h6' align='center'>
          { auth.phone_number }
        </Typography>
      </CardContent>
    </Box>
  );
}
