import React from 'react';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import CONFIG from '../../../config';
import MapMarker from './MapMarker';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = CONFIG.MAP_TOKEN;
const MAP_STYLE = 'mapbox://styles/mapbox/streets-v11';

const Map = ({ mapRef = null, lon = -0.6210313, lat = 44.8637124, zoom = 1, children }) => {
  const mapContainer = React.useRef(null);
  const map = React.useRef(null);
  const [newChildren, setNewChildren] = React.useState([]);

  React.useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: MAP_STYLE,
      center: [lon, lat],
      zoom: zoom
    });
    mapRef.current = map.current;
  });

  React.useEffect(() => {
    if (map.current) {
      const res = [];
      React.Children.forEach(children, child => {
        // Process MapMarker
        if (React.isValidElement(child) && child.type === MapMarker) {
          res.push(React.cloneElement(child, { map: map.current }));
        }
        else {
          res.push(child);
        }
      });
      setNewChildren(res);
    }
  }, [map.current, children]);

  return (
    <div ref={mapContainer} className="map-container" style={{ height: '100%' }}>
      { map ? newChildren : null}
    </div>);
};

const propTypes = {
  mapRef: PropTypes.object,
  lon: PropTypes.number,
  lat: PropTypes.number,
  zoom: PropTypes.number,
  children: PropTypes.oneOfType(PropTypes.node)
};

Map.propTypes = propTypes;

export default Map;
