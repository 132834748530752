import config from '../config';

const LOCAL_STORAGE_TOKEN = 'auth_token';
const LOCAL_STORAGE_TOKEN_VALIDITY_DATE = 'auth_token_validity_date';

const getCurrentDate = () => {
  return new Date();
};

const SetTokenValidityDate = () => {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_VALIDITY_DATE, getCurrentDate().getTime());
};

const GetTokenValidityDate = () => {
  return Number.parseInt(localStorage.getItem(LOCAL_STORAGE_TOKEN_VALIDITY_DATE));
};

const ValidTokenTime = () => {
  let validityTime = Number.parseInt(config.VUE_APP_TOKEN_DAY_VALIDITY * 24 * 60 * 60 * 1000);
  return (getCurrentDate() - GetTokenValidityDate()) < validityTime;
};

export const GetToken = () => {
  if (!ValidTokenTime())
    RemoveToken();
  return localStorage.getItem(LOCAL_STORAGE_TOKEN);
};

export const SetToken = (token) => {
  SetTokenValidityDate();
  localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
};

export const RemoveToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN);
};
