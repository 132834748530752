export const DataProviderMaterialIconDriver = (providerType) => {
  switch (providerType) {
    case 'gateway':
      return 'router';
    case 'device':
      return 'bluetooth';
    case 'autonomous-device':
    default:
      return 'router';
  }
};

export const DataProviderMetaName = (dataProvider) => {
  switch (dataProvider.provider_type) {
    case 'device':
      return dataProvider.name;
    case 'gateway':
      return `${dataProvider.provider_type} ${dataProvider.id}`;
    case 'autonomous-device':
    default:
      return `${dataProvider.provider_type} ${dataProvider.id}`;

  }
};

export const DataProviderMeta = (dataProvider) => {
  return {
    materialIcon: DataProviderMaterialIconDriver(dataProvider.provider_type),
    name: DataProviderMetaName(dataProvider)
  };
};
