import { clusterApi } from '../api';
import { BaseApiException } from '../api/exceptions';
import { enqueueErrorNotification } from '../moduleAppNotification/actions';

const processError = (dispatch, error) => {
  if (error instanceof BaseApiException
    && error.status > 300
    && error.status < 500)
    return error;
  dispatch(enqueueErrorNotification(error.message));
  throw error;
};

export const RECEIVE_API_EVENT_CONFIGURATIONS_ERROR = 'RECEIVE_API_EVENT_CONFIGURATIONS_ERROR';
const clusterApiError = (error) => {
  return { type: RECEIVE_API_EVENT_CONFIGURATIONS_ERROR, error };
};


// =========================================
// Cluster Event Configurations Global Fetch
// =========================================

export const REQUEST_API_CLUSTER_EVENT_CONFIGURATIONS = 'REQUEST_API_CLUSTER_EVENT_CONFIGURATIONS';
const requestClusterEventConfigurations = (status) => {
  return { type: REQUEST_API_CLUSTER_EVENT_CONFIGURATIONS, status };
};

export const RECEIVE_API_CLUSTER_EVENT_CONFIGURATIONS = 'RECEIVE_API_CLUSTER_EVENT_CONFIGURATIONS';
const receiveClusterEventConfigurations = (clusterEventConfigurations, clusterId) => {
  return { type: RECEIVE_API_CLUSTER_EVENT_CONFIGURATIONS, clusterEventConfigurations, clusterId };
};

export const fetchClusterEventConfigurations = (clusterId) => {
  return (dispatch) => {
    dispatch(requestClusterEventConfigurations(true));
    return clusterApi.fetchClusterEventConfigurations(clusterId)
      .then(clusterMetrics => dispatch(receiveClusterEventConfigurations(clusterMetrics, clusterId)))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))))
      .finally(() => dispatch(requestClusterEventConfigurations(false)));
  };
};


// ===================================
// Cluster Event Configurations Create
// ===================================

export const CREATE_API_CLUSTER_EVENT_CONFIGURATION = 'CREATE_API_CLUSTER_EVENT_CONFIGURATION';
const createEventConfig = (status) => ({ type: CREATE_API_CLUSTER_EVENT_CONFIGURATION, status });

export const SUCCESS_CREATE_API_CLUSTER_EVENT_CONFIGURATION = 'SUCCESS_CREATE_API_CLUSTER_EVENT_CONFIGURATION';
const createSuccessEventConfig = (clusterId, clusterEventConfiguration) => (
  { type: SUCCESS_CREATE_API_CLUSTER_EVENT_CONFIGURATION, clusterId, clusterEventConfiguration });

export const createClusterEventConfig = (clusterId, eventConfig) => {
  return (dispatch) => {
    dispatch(createEventConfig(true));
    return clusterApi.createEvent(clusterId, eventConfig)
      .then(eventConfigCreated => dispatch(createSuccessEventConfig(clusterId, eventConfigCreated)))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))))
      .finally(() => dispatch(createEventConfig(false)));
  };
};


// ===================================
// Cluster Event Configurations Update
// ===================================

export const UPDATE_API_CLUSTER_EVENT_CONFIGURATION = 'UPDATE_API_CLUSTER_EVENT_CONFIGURATION';
const updateEventConfig = (status) => ({ type: UPDATE_API_CLUSTER_EVENT_CONFIGURATION, status });

export const SUCCESS_UPDATE_API_CLUSTER_EVENT_CONFIGURATION = 'SUCCESS_UPDATE_API_CLUSTER_EVENT_CONFIGURATION';
const successUpdateEventConfig = (clusterId, clusterEventConfiguration) => (
  { type: SUCCESS_UPDATE_API_CLUSTER_EVENT_CONFIGURATION, clusterId, clusterEventConfiguration });

export const updateClusterEventConfig = (clusterId, eventConfig) => {
  return (dispatch) => {
    dispatch(updateEventConfig(true));
    return clusterApi.updateEvent(clusterId, eventConfig)
      .then(eventConfigUpdated => dispatch(successUpdateEventConfig(clusterId, eventConfigUpdated)))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))))
      .finally(() => dispatch(updateEventConfig(false)));
  };
};


// ===================================
// Cluster Event Configurations Delete
// ===================================

export const DELETE_API_CLUSTER_EVENT_CONFIGURATION = 'DELETE_API_CLUSTER_EVENT_CONFIGURATION';
const deleteEventConfig = (status) => ({ type: DELETE_API_CLUSTER_EVENT_CONFIGURATION, status });

export const SUCCESS_DELETE_API_CLUSTER_EVENT_CONFIGURATION = 'SUCCESS_DELETE_API_CLUSTER_EVENT_CONFIGURATION';
const successDeleteEventConfig = (clusterId, eventConfigId) => (
  { type: SUCCESS_DELETE_API_CLUSTER_EVENT_CONFIGURATION, clusterId, eventConfigId });

export const deleteClusterEventConfig = (clusterId, eventConfig) => {
  return (dispatch) => {
    dispatch(deleteEventConfig(true));
    return clusterApi.deleteEvent(clusterId, eventConfig.id)
      .then(() => dispatch(successDeleteEventConfig(clusterId, eventConfig.id)))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))))
      .finally(() => dispatch(deleteEventConfig(false)));
  };
};


// ==============================================
// Cluster Event Configurations Link Data Service
// ==============================================

export const linkClusterEventConfigToDataService = (clusterId, eventConfigurationId, dataServiceId) => {
  return (dispatch) => {
    return clusterApi.linkEvent(clusterId, eventConfigurationId, dataServiceId)
      .then(() => dispatch(fetchClusterEventConfigurations(clusterId)))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))));
  };
};

export const unlinkClusterEventConfigToDataService = (clusterId, eventConfigurationId, dataServiceId) => {
  return (dispatch) => {
    return clusterApi.unlinkEvent(clusterId, eventConfigurationId, dataServiceId)
      .then(() => dispatch(fetchClusterEventConfigurations(clusterId)))
      .catch(error => dispatch(clusterApiError(processError(dispatch, error))));
  };
};
