import React from 'react';
import PropTypes from 'prop-types';
import { DataProviderMinimalInfo } from '../../shared/DataProviderMinimalInfo';
import { MapPopup } from '../../../lib';
import ActionButtons from '../../shared/ActionButtons';
import { Divider, Typography } from '@material-ui/core';

const DataProviderMapPopUp = ({dataProvider, ...props}) => {

  return (
    <MapPopup {...props}>
      <DataProviderMinimalInfo dataProvider={dataProvider}/>
      <Divider />
      <Typography>
        <ActionButtons dataProvider={dataProvider} />
      </Typography>
    </MapPopup>
  );
};

DataProviderMapPopUp.propTypes = {
  dataProvider: PropTypes.object.isRequired
};

export default DataProviderMapPopUp;
