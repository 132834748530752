import { doGet } from './base';

export default {
  fetchAll() {
    return doGet('/api/client/device/');
  },
  fetch(id) {
    return doGet(`/api/client/device/${id}/`);
  }
};
