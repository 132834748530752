export const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear();
};

export const formatTime = (date, local = 'en-EN') => {
  const option = { timeStyle: 'short' };
  return Intl.DateTimeFormat(local, option).format(date);
};

export const formatFriendlyDate = (date, local = 'en-EN') => {
  const day = Math.floor((new Date().getTime() - date.getTime()) / 86400000);
  if (day <= 0)
    return `today at ${formatTime(date, local)}`;
  else if (day === 1)
    return `yesterday at ${formatTime(date, local)}`;
  else
    return `${day} days ago at ${formatTime(date, local)}`;
};

export const formatDateTime = (date, local = 'en-EN') => {
  const option = {dateStyle: 'long', timeStyle: 'short'};
  return Intl.DateTimeFormat(local, option).format(date);
};
