import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { dataRender } from '../../../../libs/dataValueHelper';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
  value: PropTypes.object,
  detail: PropTypes.object,
  date: PropTypes.string
};

const EventItem = ({ /* type, */ subtype, value, detail, date }) => {
  const classes = useStyles();
  const { renderIcon: DataServiceIcon, formatedData } = dataRender(subtype, value[0]);
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary" variant="outlined">
          <DataServiceIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h6">
            <b>{detail.name}</b>
          </Typography>
          <Typography variant="h7">
            {detail.label}
          </Typography>
          <Typography>{formatedData}</Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

EventItem.propTypes = propTypes;
export default EventItem;
