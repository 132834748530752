import { useDispatch, useSelector } from 'react-redux';
import { bluetoothDevicePair, bluetoothDeviceUnpair, fetchAutonomousDevices, fetchDevice, fetchGateway, sendCmdArmModeChange, sendCmdBluetoothScanOn } from './actions';
import * as s from './selectors';
import { gatewayCmdApi } from '../api';
import React from 'react';
import { enqueueErrorNotification, enqueueSuccessNotification } from '../actions';

const EMPTY_ARRAY = [];

// ==========
// Use device
// ==========

export const useDevice = (deviceId) => {
  return useSelector(state => s.deviceMapSelector(state)[deviceId]);
};

export const useDevicesByGateway = (gatewayId) => {
  return useSelector(state => s.devicesMapByGatewayIdSelector(state)[gatewayId] || EMPTY_ARRAY);
};

export const useScannedBluetoothDevicesByGateway = (gatewayId) => {
  const devicesMac = useDevicesByGateway(gatewayId).map(device => device.mac.toUpperCase());
  const scannedBluetoothDevices = useSelector(state => s.scannedBluetoothDevicesMapByGatewayIdSelector(state)[gatewayId] || EMPTY_ARRAY);
  const res = scannedBluetoothDevices.filter(device => !devicesMac.includes(device.mac.toUpperCase()));
  return res.reverse();
};

export const useDevicesByCluster = (cluserId) => {
  return useSelector(state => s.devicesMapByClusterIdSelector(state)[cluserId] || EMPTY_ARRAY);
};

// ===========
// Use gateway
// ===========

export const useGateway = (gatewayId) => {
  return useSelector(state => s.gatewayMapSelector(state)[gatewayId]);
};

export const useGatewaysByCluster = (clusterId) => {
  return useSelector(state => s.gatewaysMapByClusterIdSelector(state)[clusterId] || EMPTY_ARRAY);
};

// =====================
// Use autonomousDevices
// =====================

export const useAutonomousDevices = (autonomousDeviceId) => {
  return useSelector(state => s.autonomousDeviceMapSelector(state)[autonomousDeviceId]);
};

export const useAutonomousByCluster = (clusterId) => {
  return useSelector(state => s.autonomousDevicesMapByClusterIdSelector(state)[clusterId] || EMPTY_ARRAY);
};


// ================
// Use dataProvider
// ================

export const useDataProvider = (dataProviderId) => useSelector(s.dataProviderMapSelector)[dataProviderId];

export const useDataProvidersByCluster = (clusterId) => useSelector(state => s.dataProvidersMapByClusterIdSelector(state)[clusterId] || EMPTY_ARRAY);

export const useDataProviderErrorsMap = () => useSelector(s.dataProviderErrorsMapSelector);

export const useDataProviderErrorsListFilterByCluster = (clusterId) => {
  const dataProviders = useDataProvidersByCluster(clusterId);
  const dataProviderErrorsMap = useDataProviderErrorsMap();
  return dataProviders.map(dataProvider => dataProviderErrorsMap[dataProvider.id]).filter(Boolean);
};

// =====
// Fetch
// =====

export const useFetchGateway = () => {
  const dispatch = useDispatch();
  return () => dispatch(fetchGateway());
};

export const useFetchDevice = () => {
  const dispatch = useDispatch();
  return () => dispatch(fetchDevice());
};

export const useFetchAutonomouseDevice = () => {
  const dispatch = useDispatch();
  return () => dispatch(fetchAutonomousDevices());
};

// ================
// Gateway Commands
// ================

export const useScanOn = () => {
  const dispatch = useDispatch();
  return (gatewayId) => dispatch(sendCmdBluetoothScanOn(gatewayId));
};

export const useBluetoothDevicePair = () => {
  const dispatch = useDispatch();
  return (gatewayId, mac, connectivity) => dispatch(bluetoothDevicePair(gatewayId, mac, connectivity));
};

export const useBluetoothDeviceUnpair = () => {
  const dispatch = useDispatch();
  return (gatewayId, mac) => dispatch(bluetoothDeviceUnpair(gatewayId, mac));
};

export const useSetArmMode = () => {
  const dispatch = useDispatch();
  return (gatewayId, newArmMode) => dispatch(sendCmdArmModeChange(gatewayId, newArmMode));
};

// FIXME here new command hook method, we need to merge on it

const useSendGenericCommand = (apiPromise, successMessage = '', errorMessage = '') => {
  const dispatch = useDispatch();
  return React.useCallback((gateway, ...arg) => {
    return apiPromise(gateway, ...arg)
      .then(() => dispatch(enqueueSuccessNotification(`Command send correctly for gateway ${gateway.label.name ? gateway.label.name : gateway.id}`)))
      .catch(() => dispatch(enqueueErrorNotification(`Cannot send command for gateway ${gateway.label.name ? gateway.label.name : gateway.id}`)));
  }, [successMessage, errorMessage]);
};

export const useGetScenarioCommand = () => {
  return useSendGenericCommand((gateway) => gatewayCmdApi.getScenario(gateway.id));
};
