import { doDelete, doGet, doPatch, doPost, doGetImage } from './base';


export default {
  fetchAll() {
    return doGet('/api/client/cluster/');
  },
  createCluster(cluster) {
    return doPost('/api/client/cluster/', { body: cluster });
  },
  updateCluster(cluster) {
    return doPatch(`/api/client/cluster/${cluster.id}/`, { body: cluster });
  },
  deleteCluster(clusterId) {
    return doDelete(`/api/client/cluster/${clusterId}/`)
      .catch(error => {
        switch (error.status) {
          case 409:
            error.message = '';
        }
        throw error;
      });
  },
  fetchClusterMetrics(clusterId) {
    return doGet(`/api/client/cluster/${clusterId}/get-metrics/`);
  },
  fetchImageCluster(cluster) {
    return doGetImage(`/api/client/cluster/${cluster.id}/get-picture/`);
  },
  updateImageCluster(cluster, imageFile) {
    var bodyFormData = new FormData();
    bodyFormData.append('image', imageFile);
    return doPatch(`/api/client/cluster/${cluster.id}/set-picture/`, {
      body: bodyFormData,
      contentType: 'multipart/form-data'
    });
  },
  fetchEventsDocument(clusterId) {
    return doGet(`/api/client/cluster/${clusterId}/events-document/`);
  },
  fetchClusterEventConfigurations(clusterId) {
    return doGet(`/api/client/cluster/${clusterId}/data-service-event/`);
  },
  createEvent(clusterId, event) {
    return doPost(`/api/client/cluster/${clusterId}/data-service-event/`, { body: event });
  },
  updateEvent(clusterId, event) {
    return doPatch(`/api/client/cluster/${clusterId}/data-service-event/${event.id}/`, { body: event });
  },
  deleteEvent(clusterId, eventId) {
    return doDelete(`/api/client/cluster/${clusterId}/data-service-event/${eventId}/`);
  },
  linkEvent(clusterId, eventId, dataServiceId) {
    return doPost(`/api/client/cluster/${clusterId}/data-service-event/${eventId}/link/${dataServiceId}/`);
  },
  unlinkEvent(clusterId, eventId, dataServiceId) {
    return doDelete(`/api/client/cluster/${clusterId}/data-service-event/${eventId}/unlink/${dataServiceId}/`);
  },
};
