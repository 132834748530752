import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SpinnerV2 } from '../../lib';
import { useClusterContext, useClusterEventConfigurations, useClusterMetrics, useDataProvidersByCluster } from '../../../modules/hooks';


const InfoCard = ({ top, middle, bottom, isLoading = false }) => {
  return (
    <SpinnerV2 isLoading={isLoading}>
      <Box component={Paper} padding={1} color='secondary'>
        <Typography variant='h6' color='primary' noWrap> {top} </Typography>
        <Typography variant='h4' color='primary' noWrap> {middle} </Typography>
        <Typography variant='subtitle1' color='secondary' noWrap> {bottom} </Typography>
      </Box>
    </SpinnerV2>
  );
};

InfoCard.propTypes = {
  top: PropTypes.node,
  middle: PropTypes.node,
  bottom: PropTypes.node,
  isLoading: PropTypes.bool
};


export const MaterialInfoCard = () => {
  const cluster = useClusterContext();
  const dataProviders = useDataProvidersByCluster(cluster.id);
  const total = dataProviders.length;
  const online = dataProviders.filter((dp) => dp.is_online).length;
  return (
    <InfoCard
      top='Matériels enregistrés'
      middle={total}
      bottom={<b>{online} en cours de fonctionnement</b>}
    />
  );
};


export const EventInfoCard = () => {
  const cluster = useClusterContext();
  const dataCount = cluster.events.length;
  const eventConfigurations = useClusterEventConfigurations(cluster.id);
  const eventConfigurationsLinked = eventConfigurations.reduce((res, eventConfig) => res + eventConfig.data_services.length, 0);
  return (
    <InfoCard
      top='Evenements remontés'
      middle={dataCount}
      bottom={<b>{eventConfigurationsLinked} configurations</b>}
    />
  );
};


export const DataInfoCard = () => {
  const cluster = useClusterContext();
  const [clusterMetrics, isFetching] = useClusterMetrics(cluster.id);
  const dataCount = clusterMetrics ? clusterMetrics.data_metrics.all : NaN;
  const dataProviderCount = clusterMetrics ? clusterMetrics.data_provider_metrics.all : NaN;
  return (
    <InfoCard
      top='Données relevées'
      middle={dataCount}
      isLoading={(isNaN(dataCount) || isNaN(dataProviderCount)) && isFetching}
      bottom={<b>sur {dataProviderCount} capteurs</b>}
    />
  );
};


export const PhotoInfoCard = () => {
  const cluster = useClusterContext();
  const [clusterMetrics, isFetching] = useClusterMetrics(cluster.id);
  const dataCount = clusterMetrics ? clusterMetrics.data_metrics.image : NaN;
  const dataServiceCount = clusterMetrics ? clusterMetrics.data_service_metrics.image : NaN;
  return (
    <InfoCard
      top='Photos prise'
      middle={dataCount}
      isLoading={(isNaN(dataCount) || isNaN(dataServiceCount)) && isFetching}
      bottom={<b>sur {dataServiceCount} caméras</b>}
    />
  );
};


export default InfoCard;
