import { AppBar, CssBaseline, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from "react-router-dom";
import Navbar from '../components/navbar/Navbar';
import { useFetchCluster, useFetchDevice, useFetchGateway, useFetchAutonomouseDevice, useNavTitle } from '../modules/hooks';
import AccountView from './mains/AccountView';
import ClusterListView from './mains/ClusterListView';
import ClusterRouterView from './mains/ClusterRouterView';
import SettingsView from './mains/SettingsView';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      '& .MuiToolbar-regular': {
        height: theme.primaryAppBarHeight
      },
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.drawerWidth}px)`,
        marginLeft: theme.drawerWidth,
      },
    },
    appMain: {
      position: "absolute",
      left: 0,
      right: 0,
      height: `calc(100% - ${theme.primaryAppBarHeight}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.drawerWidth}px)`,
        marginLeft: theme.drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      height: theme.primaryAppBarHeight
    },
    breadcrumbs: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }
  };
});

const MainViewRouter = () => {
  const classes = useStyles();
  const fetchCluster = useFetchCluster();
  const fetchGateway = useFetchGateway();
  const fetchDevice = useFetchDevice();
  const fetchAutonomouseDevice = useFetchAutonomouseDevice();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navTitle = useNavTitle();
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  // const clusterIsFetching = useClustersIsFetching();

  React.useEffect(() => {
    fetchCluster();
    fetchGateway();
    fetchDevice();
    fetchAutonomouseDevice();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {navTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <Navbar mobileOpen={mobileOpen} onClose={handleDrawerToggle} />
      <main className={classes.appMain}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path='/'>
            <ClusterListView />
          </Route>
          <Route path='/cluster/:clusterId'>
            <ClusterRouterView />
          </Route>
          <Route path='/settings'>
            <SettingsView />
          </Route>
          <Route path='/account'>
            <AccountView />
          </Route>
        </Switch>
      </main>
    </div >
  );
};

MainViewRouter.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainViewRouter;
