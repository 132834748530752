import { Collapse, Divider, Grid, Icon, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { translateDataServiceType } from '../../libs/i18nHelper';
import { DataProviderMaterialIconDriver } from '../../libs/dataProvider';
import { useClusterContext, useDataLastValueFetcher, useDataServiceByDataProvider, useFetchDataService } from '../../modules/hooks';
import { useDevicesByGateway, useGatewaysByCluster, useAutonomousByCluster } from '../../modules/moduleDataProvider/hooks';
import { ItemListVerticalLink } from '../lib';
import ActionButtons from './shared/ActionButtons';
import GatewayBluetoothDialog from './dataProviderDetail/shared/GatewayBluetoothDialog';
import DataServiceChip from '../shared/dataService/DataServiceChip';
import DataServiceDialog from '../shared/dataService/DataServiceDialog';
import { DataProviderMinimalInfo } from './shared/DataProviderMinimalInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    '& .MuiTableCell-root': {
      paddingTop: ({ isSubDataProvider }) => isSubDataProvider ? theme.spacing(1) : theme.spacing(2),
      padding: theme.spacing(2)
    }
  },
  collapseCell: {
    borderBottom: 'unset',
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingTop: 0

  },
  firstCell: {
    minWidth: '40px',
    position: 'relative',
  },
  secondCell: {
    width: '98%',
    minWidth: '95%'
  },
  dataServiceCell: {
    display: 'flex',
    justifyContent: 'begin',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.2),
    margin: theme.spacing(0.),
  },
})
);

const DataServiceItem = ({ dataService }) => {
  const [open, setOpen] = React.useState(false);
  const dataLastValueFetcher = useDataLastValueFetcher(dataService.id);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    dataLastValueFetcher();
  }, []);
  return (
    <React.Fragment>
      <DataServiceChip
        key={dataService.id}
        dataService={dataService}
        onClick={handleOpen} />
      <DataServiceDialog
        title={translateDataServiceType(dataService)}
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        dataService={dataService} />
    </React.Fragment>
  );
};

DataServiceItem.propTypes = {
  dataService: PropTypes.object.isRequired
};

const DataServicesCell = ({ dataProviderId }) => {
  const classes = useStyles();
  const fetchDataService = useFetchDataService();
  const dataServices = useDataServiceByDataProvider(dataProviderId, false);
  React.useEffect(() => fetchDataService(dataProviderId), []);
  return (
    <div className={classes.dataServiceCell}>
      {dataServices.map(dataService => <DataServiceItem key={dataService.id} dataService={dataService} />)}
    </div>
  );
};

DataServicesCell.propTypes = {
  dataProviderId: PropTypes.number.isRequired
};

const DataProviderRow = (
  {
    dataProvider,
    onOpenClick,
    onBluetoothClick,
    openStatus,
    disabledOpen,
    isSubDataProvider,
    isLastSubDataProvider = false
  }
) => {
  const classes = useStyles({ isSubDataProvider });
  return (
    <TableRow className={classes.root}>
      {!isSubDataProvider ? null :
        <TableCell className={classes.firstCell}>
          <ItemListVerticalLink lastItem={isLastSubDataProvider} />
        </TableCell>}
      <TableCell className={classes.secondCell}>
        <Grid component={Paper} container spacing={2} alignItems="center" variant="outlined" square>
          <Grid xs={12} md={2} lg={2} item>
            <DataProviderMinimalInfo dataProvider={dataProvider} onOpenClick={onOpenClick} disabledOpen={disabledOpen} openStatus={openStatus} />
            <Divider />
            <Typography>
              <ActionButtons
                onBluetoothClick={onBluetoothClick}
                dataProvider={dataProvider} />
            </Typography>
          </Grid>
          {/* Add 31px padding to align chips with subdataprovider */}
          <Grid xs={12} md={10} lg={10} item style={{ paddingLeft: !isSubDataProvider ? "31px" : "0px" }}>
            <DataServicesCell dataProviderId={dataProvider.id} />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow >
  );
};

DataProviderRow.propTypes = {
  dataProvider: PropTypes.object.isRequired,
  connectionStatus: PropTypes.bool,
  onOpenClick: PropTypes.func,
  onBluetoothClick: PropTypes.func,
  openStatus: PropTypes.bool,
  disabledOpen: PropTypes.bool,
  actionCell: PropTypes.node,
  isSubDataProvider: PropTypes.bool,
  isLastSubDataProvider: PropTypes.bool
};

const GatewayRow = ({ gatewayDataProvider }) => {
  const [open, setOpen] = React.useState(true);
  const [openScan, setOpenScan] = React.useState(false);
  const devices = useDevicesByGateway(gatewayDataProvider.id);
  const classes = useStyles();
  return (
    <React.Fragment>
      <GatewayBluetoothDialog gatewayId={gatewayDataProvider.id} onClose={() => setOpenScan(false)} open={openScan} />
      <DataProviderRow
        dataProvider={gatewayDataProvider}
        onOpenClick={() => setOpen(!open)}
        openStatus={open}
        disabledOpen={devices.length < 1}
        onBluetoothClick={() => setOpenScan(true)}
        icon={(<Icon size='small'>{DataProviderMaterialIconDriver(gatewayDataProvider.provider_type)}</Icon>)} />
      <TableRow>
        <TableCell className={classes.collapseCell} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit >
            <Table>
              <TableBody>
                {devices.map((device, idx) => (
                  <DataProviderRow key={device.id}
                    dataProvider={device}
                    icon={(<Icon size='small'>{DataProviderMaterialIconDriver(device.provider_type)}</Icon>)}
                    isSubDataProvider={true}
                    isLastSubDataProvider={devices.length === (idx + 1)} />))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment >);
};

GatewayRow.propTypes = {
  gatewayDataProvider: PropTypes.object.isRequired
};

const AutonomousDeviceRow = ({ autonomousDeviceDataProvider }) => {
  return (
    <React.Fragment>
      <DataProviderRow
        dataProvider={autonomousDeviceDataProvider}
        icon={(<Icon size='small'>{DataProviderMaterialIconDriver(autonomousDeviceDataProvider.provider_type)}</Icon>)} />
    </React.Fragment >);
};

AutonomousDeviceRow.propTypes = {
  autonomousDeviceDataProvider: PropTypes.object.isRequired
};


const DataProviderList = () => {
  const cluster = useClusterContext();
  const gateways = useGatewaysByCluster(cluster.id);
  const autonomousDevices = useAutonomousByCluster(cluster.id);
  return (
    <TableContainer style={{ overflowY: 'hidden' }}>
      <Table>
        <TableBody>
          {gateways.map((gateway) => (
            <GatewayRow key={gateway.id} gatewayDataProvider={gateway} />
          ))}
          {autonomousDevices.map((autonomousDevice) => (
            <AutonomousDeviceRow key={autonomousDevice.id} autonomousDeviceDataProvider={autonomousDevice} />
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
};

export default DataProviderList;
